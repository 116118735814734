/*------------------------------------
  Navigation Dropdown Style v1
------------------------------------*/

%link-active-state {
  color: $g-color-primary !important;
  background-color: transparent;
  border: none;
}

.navbar .u-sub-menu-v1 {
  .hs-has-sub-menu {
    .hs-has-sub-menu {
      > a::after {
        content: '\e902';
        font-family: 'hs-icons';
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate3d(0, -50%, 0);
      }

      &.active > a,
      &:active > a,
      &:focus > a,
      &:hover > a {
        @extend %link-active-state;
      }
    }

    .active > a {
      @extend %link-active-state;
    }
  }

  .hs-has-mega-menu {
    .hs-has-sub-menu {
      > a::after {
        content: '\e902';
      }
    }
  }

  .hs-sub-menu,
  .dropdown-menu {
    @include px-to-rem(font-size, 13px);
    @include px-to-rem(min-width, 230px);
    @include px-to-rem(padding, 10px 0);

    .dropdown-item {
      font-weight: inherit;
      color: inherit;
      padding: 0;

      &.active,
      &:active,
      &:focus,
      &:hover {
        border: none;
        background-color: transparent;

        > a {
          @extend %link-active-state;
        }
      }
    }

    a {
      color: $g-color-gray-dark-v4;
      text-decoration: none !important;
      display: block;
      @include px-to-rem(padding, 6px 25px);

      &:active,
      &:focus,
      &:hover {
        @extend %link-active-state;
      }
    }
  }

  .hs-sub-menu,
  .hs-mega-menu,
  .dropdown-menu {
    font-weight: normal;
    text-transform: none;
    @include px-to-rem(margin-top, 0);
    color: inherit;
    background-color: $g-color-white;
    background-clip: border-box;
    border-width: 3px 0 0 0;
    border-style: solid;
    border-color: $g-color-primary;
    border-radius: 0;
    box-shadow: 0 6px 12px rgba($g-color-black, 0.175);

    .hs-sub-menu {
      @include px-to-rem(margin-top, -15px);
    }
  }

  &.hs-mobile-state {
    .hs-sub-menu {
      width: auto;
    }

    .hs-sub-menu,
    .hs-mega-menu {
      .hs-sub-menu,
      .hs-mega-menu,
      .hs-sub-menu.hs-reversed,
      .hs-mega-menu.hs-reversed {
        @include px-to-rem(margin, 3px);
      }
    }
  }
}

.navbar .hs-mobile-state .u-sub-menu-v1 {
  .hs-sub-menu {
    width: auto;
  }

  .hs-sub-menu,
  .hs-mega-menu {
    .hs-sub-menu,
    .hs-mega-menu,
    .hs-sub-menu.hs-reversed,
    .hs-mega-menu.hs-reversed {
      @include px-to-rem(margin, 3px);
    }
  }
}
