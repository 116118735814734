/*------------------------------------
  Sidebar Navigation
------------------------------------*/

.u-sidebar-navigation {
  @media all and (min-width: #{$g-lg}) {
    position: fixed;
    top: 0;
    @include px-to-rem(left, -300px);
    width: 300px;
  }

  &-inner {
    background-color: #1c2434;
    color: $g-color-white;
    padding: 20px 0;
  }

  &__search-input {
    border-radius: 3px;
    border: none;
  }

  .nav-link {
    color: $g-color-white;

    @include px-to-rem(padding, 7px 20px);

    &:hover {
      background-color: rgba($g-color-white, 0.15);
    }
    &.active {
      background-color: rgba($g-color-white, 0.1);
    }
  }

  &__toggler {
    position: fixed;
    top: 120px;
    left: 0;
    width: 53px;
    height: 53px;
    background-color: #1c2434;
    color: $g-color-white;
    border-radius: 0 3px 3px 0;
    font-size: 22px;
    z-index: 2;

    &:hover,
    &:focus {
      color: $g-color-white;
    }
  }

  &__closer {
    position: absolute;
    top: 22px;
    right: 15px;
    width: 25px;
    height: 25px;
    padding: 0;
    color: $g-color-white;
    background-color: transparent;
    z-index: 3;

    &:hover,
    &:focus {
      color: $g-color-white;
    }
  }

  @media all and (max-width: #{$g-lg - 1}) {
    &-list {
      display: block;
      columns: 4;

      .dropdown-toggle {
        display: none;
      }
      .dropdown-menu {
        display: block;
        max-height: 100%;
        margin: 0;
        float: none;
        opacity: 1;
      }
    }
  }
  @media all and (max-width: #{$g-md - 1}) {
    &-list {
      columns: 3;
    }
  }
  @media all and (max-width: #{$g-sm - 1}) {
    &-list {
      columns: 1;
    }
  }
}

.u-has-sidebar-navigation .u-header__overlay {
  display: none;
}
