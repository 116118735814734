/*------------------------------------
  Heading-v6
------------------------------------*/
.u-heading-v6__title {
  display: inline-block;
  position: relative;
}
%u-heading-v6__title {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  @include px-to-rem(width, 70px);
  border-top-width: 1px;
  // border-top-width: 2px;
  border-top-style: solid;
  border-color: inherit;
}

//
// Imports
//

@import 'heading-v6-1';
@import 'heading-v6-2';
