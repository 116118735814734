/*------------------------------------
  Zoom Effect v1
------------------------------------*/
.u-icon-block--hover:hover .u-icon-scale-1_2--hover {
  transform: scale(1.2);
}
.u-icon-rotation.u-icon-scale-1_2--hover:hover,
.u-icon-block--hover:hover .u-icon-rotation.u-icon-scale-1_2--hover {
  transform: scale(1.2) rotate(45deg);
}
