/*------------------------------------
  Nonstandard Background v1
------------------------------------*/

[class*='u-ns-bg-v1'] {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 3;
    width: 0;
    height: 0;
    border: {
      style: solid;
      color: transparent;
    }
  }
}

.u-ns-bg-v1 {
  &-top,
  &-bottom {
    &::before {
      left: 50%;
      margin-left: -20px;
    }
  }

  &-left,
  &-right {
    &::before {
      top: 50%;
      margin-top: -20px;
    }
  }

  &-top {
    &::before {
      top: -25px;
      border-width: 0 20px 25px 20px;
    }

    &.g-bg-primary,
    .g-parent.g-bg-primary & {
      &::before {
        border-bottom-color: $g-color-primary;
      }
    }

    &.g-bg-white,
    .g-parent.g-bg-white & {
      &::before {
        border-bottom-color: $g-color-white;
      }
    }

    &.g-bg-gray-light-v2,
    .g-parent.g-bg-gray-light-v2 & {
      &::before {
        border-bottom-color: $g-color-gray-light-v2;
      }
    }

    &.g-bg-black,
    .g-parent.g-bg-black & {
      &::before {
        border-bottom-color: $g-color-black;
      }
    }
  }

  &-bottom {
    &::before {
      bottom: -25px;
      border-width: 25px 20px 0 20px;
    }

    &.g-bg-primary,
    .g-parent.g-bg-primary & {
      &::before {
        border-top-color: $g-color-primary;
      }
    }

    &.g-bg-white,
    .g-parent.g-bg-white & {
      &::before {
        border-top-color: $g-color-white;
      }
    }

    &.g-bg-gray-light-v2,
    .g-parent.g-bg-gray-light-v2 & {
      &::before {
        border-top-color: $g-color-gray-light-v2;
      }
    }

    &.g-bg-black,
    .g-parent.g-bg-black & {
      &::before {
        border-top-color: $g-color-black;
      }
    }
  }

  &-left {
    &::before {
      left: -25px;
      border-width: 20px 25px 20px 0;
    }

    &.g-bg-primary,
    .g-parent.g-bg-primary & {
      &::before {
        border-right-color: $g-color-primary;
      }
    }

    &.g-bg-white,
    .g-parent.g-bg-white & {
      &::before {
        border-right-color: $g-color-white;
      }
    }

    &.g-bg-gray-light-v2,
    .g-parent.g-bg-gray-light-v2 & {
      &::before {
        border-right-color: $g-color-gray-light-v2;
      }
    }

    &.g-bg-black,
    .g-parent.g-bg-black & {
      &::before {
        border-right-color: $g-color-black;
      }
    }
  }

  &-right {
    &::before {
      right: -25px;
      border-width: 20px 0 20px 25px;
    }

    &.g-bg-primary,
    .g-parent.g-bg-primary & {
      &::before {
        border-left-color: $g-color-primary;
      }
    }

    &.g-bg-white,
    .g-parent.g-bg-white & {
      &::before {
        border-left-color: $g-color-white;
      }
    }

    &.g-bg-gray-light-v2,
    .g-parent.g-bg-gray-light-v2 & {
      &::before {
        border-left-color: $g-color-gray-light-v2;
      }
    }

    &.g-bg-black,
    .g-parent.g-bg-black & {
      &::before {
        border-left-color: $g-color-black;
      }
    }
  }
}

@media (min-width: $g-md) {
  .u-ns-bg-v1 {
    &-top--md,
    &-bottom--md,
    &-left--md,
    &-right--md {
      &::before {
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        margin: {
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }

      &.g-bg-primary,
      &.g-bg-white,
      &.g-bg-gray-light-v2,
      &.g-bg-black {
        &::before {
          border-top-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
        }
      }
    }

    &-top--md,
    &-bottom--md {
      &::before {
        left: 50%;
        margin-left: -20px;
      }
    }

    &-left--md,
    &-right--md {
      &::before {
        top: 50%;
        margin-top: -20px;
      }
    }

    &-top--md {
      &::before {
        top: -25px;
        border-width: 0 20px 25px 20px;
      }

      &.g-bg-primary {
        &::before {
          border-bottom-color: $g-color-primary;
        }
      }

      &.g-bg-white {
        &::before {
          border-bottom-color: $g-color-white;
        }
      }

      &.g-bg-gray-light-v2 {
        &::before {
          border-bottom-color: $g-color-gray-light-v2;
        }
      }

      &.g-bg-black {
        &::before {
          border-bottom-color: $g-color-black;
        }
      }
    }

    &-bottom--md {
      &::before {
        bottom: -25px;
        border-width: 25px 20px 0 20px;
      }

      &.g-bg-primary {
        &::before {
          border-top-color: $g-color-primary;
        }
      }

      &.g-bg-white {
        &::before {
          border-top-color: $g-color-white;
        }
      }

      &.g-bg-gray-light-v2 {
        &::before {
          border-top-color: $g-color-gray-light-v2;
        }
      }

      &.g-bg-black {
        &::before {
          border-top-color: $g-color-black;
        }
      }
    }

    &-left--md {
      &::before {
        left: -25px;
        border-width: 20px 25px 20px 0;
      }

      &.g-bg-primary {
        &::before {
          border-right-color: $g-color-primary;
        }
      }

      &.g-bg-white {
        &::before {
          border-right-color: $g-color-white;
        }
      }

      &.g-bg-gray-light-v2 {
        &::before {
          border-right-color: $g-color-gray-light-v2;
        }
      }

      &.g-bg-black {
        &::before {
          border-right-color: $g-color-black;
        }
      }
    }

    &-right--md {
      &::before {
        right: -25px;
        border-width: 20px 0 20px 25px;
      }

      &.g-bg-primary {
        &::before {
          border-left-color: $g-color-primary;
        }
      }

      &.g-bg-white {
        &::before {
          border-left-color: $g-color-white;
        }
      }

      &.g-bg-gray-light-v2 {
        &::before {
          border-left-color: $g-color-gray-light-v2;
        }
      }

      &.g-bg-black {
        &::before {
          border-left-color: $g-color-black;
        }
      }
    }
  }
}
