/*------------------------------------
  Box-shadows Styles
------------------------------------*/

.u-shadow-none {
  box-shadow: none !important;

  &--focus:focus {
    box-shadow: none !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      box-shadow: none !important;
    }
  }
}
