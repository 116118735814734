/*------------------------------------
  Searchform v2
------------------------------------*/

.u-searchform-v2 {
  .form-control {
    font-family: $g-font-primary;
    font-weight: 600;
    @include px-to-rem(font-size, 12px);
    color: rgba($g-color-white, 0.85);
    text-transform: uppercase;

    @include px-to-rem(height, 66px);
    @include px-to-rem(padding, 12px 0);

    background-color: transparent;
    border: none;

    &::placeholder {
      color: g-color-white;
      opacity: 0.85;
    }

    &::-webkit-input-placeholder {
      color: g-color-white;
      opacity: 0.85;
    }

    &::-moz-placeholder {
      color: g-color-white;
      opacity: 0.85;
    }
  }

  .input-group-addon {
    border: 0;

    button {
      cursor: pointer;
      height: 100%;

      border: none;
      outline: none;
      background-color: transparent;
    }
  }
}
