/*------------------------------------
  Info Block v1-4
------------------------------------*/
.u-info-v1-4__item-hidden {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 61px;
  overflow: hidden;
  transform: translate3d(0, 61px, 0);
}
.u-info-v1-4:hover .u-info-v1-4__item-hidden {
  transform: translate3d(0, 0, 0);
}
.u-info-v1-4:hover .u-info-v1-4__item-regular {
  transform: translate3d(0, -61px, 0);
}
