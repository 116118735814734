/*------------------------------------
  Progress Bars
------------------------------------*/
//
// Pointers
//

.progress-bar {
  min-height: 0 !important;
  display: block;
  line-height: 1.4;
  height: 1rem;
}

.u-progress__pointer-v1 {
  position: absolute;
  top: 50%;
  right: 0;
  width: 42px;
  height: 42px;
  margin: -21px -21px 0 0;
  background-color: transparent;
  line-height: 42px;
  z-index: 1;
}

.u-progress__pointer-v2 {
  position: absolute;
  bottom: 100%;
  right: 0;
  min-width: 46px;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  transform: translateX(50%);
  margin-bottom: 5px;
  background-color: transparent;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -5px;
    margin-left: -5px;
    border: {
      style: solid;
      color: $g-color-primary transparent;
      width: 5px 5px 0;
    }
  }
}

.u-progress__pointer-v3 {
  position: relative;
  float: right;
  white-space: nowrap;
  line-height: 1.2;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -10px;
    margin-right: -5px;
    border: {
      style: solid;
      color: $g-color-primary transparent;
      width: 5px 5px 0;
    }
  }
}

//
// Vertical Progress Bars
//

[class*='u-progress-bar-vertical'] {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}
.u-progress-bar-vertical-v1 {
  height: 200px;
}
