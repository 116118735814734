/*------------------------------------
  Sliders v1
------------------------------------*/

.u-slider {
  &-v1,
  &-v1-2,
  &-v1-3 {
    &.ui {
      &-slider {
        position: relative;
        background: $g-color-gray-light-v4;
        border: none;
        border-radius: 0;
        margin: {
          top: 12px;
          left: 6px;
          right: 6px;
        }

        .ui-slider {
          &-range {
            height: 100%;
          }

          &-handle {
            position: absolute;
            top: 50%;
            border-style: solid;
            outline: none;
            background: $g-color-white;
            border-radius: 0;
            cursor: pointer;
            transition: {
              property: border-color;
              duration: 0.3s;
              timing-function: ease;
            }
          }
        }

        &.ui-slider-content {
          border-color: $g-color-gray-light-v4;
        }
      }
    }
  }

  &-v1 {
    &.ui {
      &-slider {
        height: 2px;

        .ui-slider {
          &-range {
            background: $g-color-primary;
          }

          &-handle {
            width: 20px;
            height: 20px;
            margin: {
              top: -10px;
              left: -10px;
            }
            border: {
              width: 2px;
              color: $g-color-primary;
            }
          }
        }
      }
    }
  }

  &-v1-2,
  &-v1-3 {
    &.ui {
      &-slider {
        height: 4px;

        .ui-slider {
          &-range {
            background: $g-color-gray-light-v3;
          }

          &-handle {
            width: 15px;
            height: 15px;
            margin: {
              top: -8px;
              left: -8px;
            }
            border: {
              width: 2px;
              color: $g-color-primary;
            }
          }
        }
      }
    }
  }

  &-v1-3 {
    &.ui {
      &-slider {
        .ui-slider {
          &-handle {
            border-radius: 50%;
          }
        }
      }
    }
  }
}
