/*------------------------------------
  Icon-v4
------------------------------------*/
@import 'icons-sizes-vn';

.u-icon-v4 {
  @include px-to-rem(padding, 8px);
  background: transparent !important;
  border: solid 1px $g-color-gray-light-v4;
  transition: all 0.1s ease-in-out;

  &-inner {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: $g-color-gray-light-v4;
  }
}

.u-icon-v4 .u-icon-v4-inner {
  @extend %u-icon-size;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

//
// Sizes
//

.u-icon-v4 {
  // Default (md)
  & .u-icon__elem-regular,
  & .u-icon__elem-hover {
    @extend %u-icon-size;
  }

  &.u-icon-size--xs &-inner,
  &.u-icon-size--xs .u-icon__elem-regular,
  &.u-icon-size--xs .u-icon__elem-hover {
    @extend %u-icon-size--xs;
  }

  &.u-icon-size--sm &-inner,
  &.u-icon-size--sm .u-icon__elem-regular,
  &.u-icon-size--sm .u-icon__elem-hover {
    @extend %u-icon-size--sm;
  }

  &.u-icon-size--lg &-inner,
  &.u-icon-size--lg .u-icon__elem-regular,
  &.u-icon-size--lg .u-icon__elem-hover {
    @extend %u-icon-size--lg;
  }

  &.u-icon-size--xl &-inner,
  &.u-icon-size--xl .u-icon__elem-regular,
  &.u-icon-size--xl .u-icon__elem-hover {
    @extend %u-icon-size--xl;
  }

  &.u-icon-size--2xl &-inner,
  &.u-icon-size--2xl .u-icon__elem-regular,
  &.u-icon-size--2xl .u-icon__elem-hover {
    @extend %u-icon-size--2xl;
  }

  &.u-icon-size--3xl &-inner,
  &.u-icon-size--3xl .u-icon__elem-regular,
  &.u-icon-size--3xl .u-icon__elem-hover {
    @extend %u-icon-size--3xl;
  }
}

//
// Border-radiuses
//

.u-icon-v4 {
  &-rounded-3,
  &-rounded-3 &-inner {
    border-radius: 3px;
  }

  &-rounded-7,
  &-rounded-7 &-inner {
    border-radius: 7px;
  }

  &-rounded-10,
  &-rounded-10 &-inner {
    border-radius: 10px;
  }

  &-rounded-50x,
  &-rounded-50x &-inner {
    border-radius: 50%;
  }
}

//
// Colors
//

.u-icon-v4 {
  &-bg-primary {
    border-color: $g-color-primary;

    .u-icon-v4-inner {
      background: $g-color-primary;
    }
  }

  &-bg-white {
    border-color: $g-color-white;

    .u-icon-v4-inner {
      background: $g-color-white;
    }
  }

  &-bg-gray-light-v3 {
    border-color: $g-color-gray-light-v3;

    .u-icon-v4-inner {
      background: $g-color-gray-light-v3;
    }
  }

  &-bg-gray-dark-v3 {
    border-color: $g-color-gray-dark-v3;

    .u-icon-v4-inner {
      background: $g-color-gray-dark-v3;
    }
  }

  &-bg-black {
    border-color: $g-color-black;

    .u-icon-v4-inner {
      background: $g-color-black;
    }
  }

  //
  // Hover Effects
  //

  // Primary color
  &-bg-primary--hover:hover,
  .u-icon-block--hover:hover &-bg-primary--hover {
    border-color: $g-color-primary;

    & .u-icon-v4-inner {
      background: $g-color-primary;
    }
  }

  // White color
  &-bg-white--hover:hover,
  .u-icon-block--hover:hover &-bg-white--hover {
    border-color: $g-color-white;

    & .u-icon-v4-inner {
      background: $g-color-white;
    }
  }

  // Gray-light-v3 color
  &-bg-gray-light-v3--hover:hover,
  .u-icon-block--hover:hover &-bg-gray-light-v3--hover {
    border-color: $g-color-gray-light-v3;

    & .u-icon-v4-inner {
      background: $g-color-gray-light-v3;
    }
  }

  // Gray-dark-v3 color
  &-bg-gray-dark-v3--hover:hover,
  .u-icon-block--hover:hover &-bg-gray-dark-v3--hover {
    border-color: $g-color-gray-dark-v3;

    & .u-icon-v4-inner {
      background: $g-color-gray-dark-v3;
    }
  }

  // Black color
  &-bg-black--hover:hover,
  .u-icon-block--hover:hover &-bg-black--hover {
    border-color: $g-color-black;

    & .u-icon-v4-inner {
      background: $g-color-black;
    }
  }
}
@import 'icon-v4-additional-colors';
@import 'icon-v4-social-colors';
@import 'icon-v4-gradients';
