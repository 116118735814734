/*--------------------------------------------------
  Chart Pie v1
----------------------------------------------------*/

.u-chart-pie-v1 {
  position: relative;
  line-height: 1em;
}
.u-chart-pie-v1::after {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  right: 10px;
  bottom: 11px;
  left: 10px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
}
