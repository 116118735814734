/*------------------------------------
  Compressed Form
------------------------------------*/

.u-compressed-form {
  display: inline-block;
  overflow: hidden;

  @include px-to-rem(width, 280px);

  transition: all 0.35s ease;

  &--hidden {
    @include px-to-rem(width, 80px);
  }

  .input-group {
    display: block;
    float: right;
  }
}
