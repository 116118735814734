/*------------------------------------
  Marker-bg
------------------------------------*/

.u-marker-bg {
  &-primary,
  &-green,
  &-black,
  &-white,
  &-red,
  &-yellow {
    display: inline-block;
    background: {
      repeat: no-repeat;
      position: 0 50%;
      size: 100% 70%;
    }
    white-space: nowrap;
  }

  &-primary {
  }
  &-green {
  }
  &-black {
  }
  &-white {
  }
  &-red {
  }
  &-yellow {
  }
}
