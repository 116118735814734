//
// Divider Dobule Dashed Style
//

/* Double Dashed Divider */
.u-divider-db-dashed {
  height: 5px;
  border-top: 1px dashed transparent;
  border-bottom: 1px dashed transparent;
}
