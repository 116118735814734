/*------------------------------------
  Tabs v5
------------------------------------*/
@media (min-width: $g-md) {
  [class*='u-nav-v5'] {
    .nav-item {
      margin-bottom: -1px;
    }

    & .nav-link {
      border-bottom: {
        style: solid;
        width: 1px;
        color: transparent;
      }
    }

    & .nav-link.active {
      border-color: $g-color-gray-light-v2;
    }

    &.u-nav-primary .nav-link.active {
      border-color: $g-color-primary;
      color: $g-color-primary;
    }

    &.u-nav-dark .nav-link.active {
      border-color: $g-color-gray-dark-v2;
      color: $g-color-gray-dark-v2;
    }

    &.u-nav-light {
      & .nav-link {
        color: $g-color-white;
      }

      & .nav-link.active {
        color: $g-color-white;
        border-color: $g-color-white;
      }
    }
  }

  .u-nav-v5-2 {
    & .nav-link {
      border-bottom-width: 2px;
    }
  }

  .u-nav-v5-3 {
    & .nav-link {
      border-bottom-width: 5px;
    }
  }
}
