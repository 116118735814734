/*------------------------------------
  Effect v5
------------------------------------*/
@import 'icon-hover-extend';

.u-icon-effect-v5--hover {
  transition: box-shadow 0.2s;
  overflow: inherit;
}

.u-icon-effect-v5--hover:after {
  @extend %u-icon-hover-effect-after;
  top: 0;
  left: 0;
  padding: 0;
  transition: transform 0.2s, opacity 0.2s;
}

.u-icon-v3.u-icon-effect-v5--hover:after {
  box-shadow: 0 0 0 3px #fff;
}

.u-icon-effect-v5--hover:hover:after,
.u-icon-block--hover:hover .u-icon-effect-v5--hover:after {
  transform: scale(0.85);
  opacity: 0.5;
}

.u-icon-effect-v5--hover:hover,
.u-icon-block--hover:hover .u-icon-effect-v5--hover {
  box-shadow: 0 0 10px 10px $g-color-gray-light-v3;
}

.u-icon-effect-v5--hover.g-bg-primary:hover,
.u-icon-block--hover:hover .u-icon-effect-v5--hover.g-bg-primary,
.u-icon-effect-v5--hover.g-color-primary:hover,
.u-icon-block--hover:hover .u-icon-effect-v5--hover.g-color-primary {
  box-shadow: 0 0 10px 10px $g-color-primary;
}
