/*------------------------------------
  Navigation
------------------------------------*/

/* Base Abstractions */
%u-main-nav-base-link {
  display: block;
}

%u-main-nav-has-sub-menu {
  > a {
    position: relative;
    z-index: 1;

    @include px-to-rem(padding-right, 20px);

    &::after {
      content: '\e900';
      font-family: 'hs-icons';
      font-weight: inherit;
      line-height: 1;

      position: absolute;
      top: 50%;
      @include px-to-rem(right, 15px);

      transform: translate3d(0, -50%, 0);
    }
  }
}

/* Base stylesheets */
.u-header__section {
  &--dark {
    .navbar [class*='u-main-nav-v'] {
      .nav-link {
        color: $g-color-white;
      }

      .nav-item {
        &.active > .nav-link,
        &:hover > .nav-link,
        &:focus > .nav-link,
        &.show > .nav-link,
        &.hs-sub-menu-opened > .nav-link,
        .nav-link:focus {
          color: $g-color-white;
        }
      }
    }
  }
}

/* Import */

/* Main navigation styles */
@import 'navigation-v1';
@import 'navigation-v2';
@import 'navigation-v3';
@import 'navigation-v4';
@import 'navigation-v5';
@import 'navigation-v6';
@import 'navigation-v7';
@import 'navigation-v8';
@import 'navigation-v9';

/* Dropdown navigation styles */
@import 'navigation-dropdown-v1';
@import 'navigation-dropdown-v2';
@import 'navigation-dropdown-v3';
@import 'navigation-dropdown-v4';
@import 'navigation-dropdown-v5';
@import 'navigation-dropdown-inline';
@import 'navigation-dropdown-columns';
@import 'navigation-dropdown-static';

/* Main navigation behaviors */
@import 'navigation-overlay';
@import 'navigation-push';
@import 'navigation-smart';

/* Secondary navigation styles */
@import 'navigation-secondary';

/* Sidebar shortcode navigation styles */
@import 'navigation-shortcode-sidebar';

/* Override vendor stylesheets */
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: inherit;
}

[class*='u-main-nav-'] .dropdown-toggle::after {
  width: auto;
  height: auto;
  vertical-align: inherit;
  border: none;
}

/* Overlay */
.u-main-nav__overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2000%;
  opacity: 0;
  visibility: hidden;
}

/* Override necessary navigation styles in side-header pages */
body.u-body--header-side {
  &-static-left {
    .navbar [class*='u-main-nav-'] {
      .dropdown-toggle::after,
      > .hs-has-sub-menu > a::after,
      > .hs-has-mega-menu > a::after {
        content: '\e900';
      }
    }
  }

  &-static-right {
    .navbar [class*='u-main-nav-'] {
      .dropdown-toggle::after,
      > .hs-has-sub-menu > a::after,
      > .hs-has-mega-menu > a::after {
        content: '\e901';
      }
    }
  }
}

/* Scroll on mobile devices */
.u-nav-mobile-scroll {
  @media (max-width: #{$g-lg - 1}) {
    overflow-y: auto;
  }
}
