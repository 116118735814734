/*------------------------------------
  Heading-v5-1
------------------------------------*/
.u-heading-v5-1 {
  & .u-heading-v5__title {
    @include px-to-rem(padding-top, 25px);
  }
  & .u-heading-v5__title::before {
    top: 0;
    left: 2px;
  }
  &.text-center .u-heading-v5__title::before {
    left: 50%;
    margin-left: -6px;
  }
  &.text-right .u-heading-v5__title::before {
    left: auto;
    right: 2px;
  }
}
