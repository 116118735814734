/*------------------------------------
  Effect v3
------------------------------------*/
@keyframes spinAround {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

.u-icon-effect-v3--hover:hover i,
.u-icon-block--hover:hover .u-icon-effect-v3--hover i {
  animation: spinAround 1s linear infinite;
}
