/* Outline Button Primary */
.u-btn-outline-primary {
  @include u-button-outline($g-color-primary, $g-color-primary, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-primary);
  @include u-button-outline-hover(1, 2, $g-color-primary);
  @include u-button-outline-hover(1, 3, $g-color-primary);
  @include u-button-outline-hover(1, 4, $g-color-primary);
  @include u-button-outline-hover(2, 1, $g-color-primary);
  @include u-button-outline-hover(2, 2, $g-color-primary);
  @include u-button-outline-hover(3, 1, $g-color-primary);
}

/* Outline Button White */
.u-btn-outline-white {
  @include u-button-outline(
    $g-color-white,
    $g-color-white,
    $g-color-gray-dark-v3
  );
  @include u-button-outline-hover(1, 1, $g-color-white);
  @include u-button-outline-hover(1, 2, $g-color-white);
  @include u-button-outline-hover(1, 3, $g-color-white);
  @include u-button-outline-hover(1, 4, $g-color-white);
  @include u-button-outline-hover(2, 1, $g-color-white);
  @include u-button-outline-hover(2, 2, $g-color-white);
}

/* Outline Button Black */
.u-btn-outline-black {
  @include u-button-outline($g-color-black, $g-color-black, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-black);
  @include u-button-outline-hover(1, 2, $g-color-black);
  @include u-button-outline-hover(1, 3, $g-color-black);
  @include u-button-outline-hover(1, 4, $g-color-black);
  @include u-button-outline-hover(2, 1, $g-color-black);
  @include u-button-outline-hover(2, 2, $g-color-black);
}

/* Outline Button Dark Gray */
.u-btn-outline-darkgray {
  @include u-button-outline(
    $g-color-gray-dark-v2,
    $g-color-gray-dark-v2,
    $g-color-white
  );
  @include u-button-outline-hover(1, 1, $g-color-gray-dark-v2);
  @include u-button-outline-hover(1, 2, $g-color-gray-dark-v2);
  @include u-button-outline-hover(1, 3, $g-color-gray-dark-v2);
  @include u-button-outline-hover(1, 4, $g-color-gray-dark-v2);
  @include u-button-outline-hover(2, 1, $g-color-gray-dark-v2);
  @include u-button-outline-hover(2, 2, $g-color-gray-dark-v2);
}

/* Outline Button Light Gray */
.u-btn-outline-lightgray {
  @include u-button-outline(
    $g-color-gray-light-v4,
    $g-color-main,
    $g-color-main
  );
  @include u-button-outline-hover(1, 1, $g-color-gray-light-v4);
  @include u-button-outline-hover(1, 2, $g-color-gray-light-v4);
  @include u-button-outline-hover(1, 3, $g-color-gray-light-v4);
  @include u-button-outline-hover(1, 4, $g-color-gray-light-v4);
  @include u-button-outline-hover(2, 1, $g-color-gray-light-v4);
  @include u-button-outline-hover(2, 2, $g-color-gray-light-v4);
}

/* Button Outline Red */
.u-btn-outline-red {
  @include u-button-outline($g-color-red, $g-color-red, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-red);
  @include u-button-outline-hover(1, 2, $g-color-red);
  @include u-button-outline-hover(1, 3, $g-color-red);
  @include u-button-outline-hover(1, 4, $g-color-red);
  @include u-button-outline-hover(2, 1, $g-color-red);
  @include u-button-outline-hover(2, 2, $g-color-red);
}

/* Button Outline Red Tomato */
.u-btn-outline-lightred {
  @include u-button-outline(
    $g-color-lightred,
    $g-color-lightred,
    $g-color-white
  );
  @include u-button-outline-hover(1, 1, $g-color-lightred);
  @include u-button-outline-hover(1, 2, $g-color-lightred);
  @include u-button-outline-hover(1, 3, $g-color-lightred);
  @include u-button-outline-hover(1, 4, $g-color-lightred);
  @include u-button-outline-hover(2, 1, $g-color-lightred);
  @include u-button-outline-hover(2, 2, $g-color-lightred);
}

/* Outline Button Dark Red */
.u-btn-outline-darkred {
  @include u-button-outline($g-color-darkred, $g-color-darkred, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-darkred);
  @include u-button-outline-hover(1, 2, $g-color-darkred);
  @include u-button-outline-hover(1, 3, $g-color-darkred);
  @include u-button-outline-hover(1, 4, $g-color-darkred);
  @include u-button-outline-hover(2, 1, $g-color-darkred);
  @include u-button-outline-hover(2, 2, $g-color-darkred);
}

/* Outline Button Blue */
.u-btn-outline-blue {
  @include u-button-outline($g-color-blue, $g-color-blue, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-blue);
  @include u-button-outline-hover(1, 2, $g-color-blue);
  @include u-button-outline-hover(1, 3, $g-color-blue);
  @include u-button-outline-hover(1, 4, $g-color-blue);
  @include u-button-outline-hover(2, 1, $g-color-blue);
  @include u-button-outline-hover(2, 2, $g-color-blue);
}

/* Outline Button Indigo */
.u-btn-outline-indigo {
  @include u-button-outline($g-color-indigo, $g-color-indigo, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-indigo);
  @include u-button-outline-hover(1, 2, $g-color-indigo);
  @include u-button-outline-hover(1, 3, $g-color-indigo);
  @include u-button-outline-hover(1, 4, $g-color-indigo);
  @include u-button-outline-hover(2, 1, $g-color-indigo);
  @include u-button-outline-hover(2, 2, $g-color-indigo);
}

/* Outline Button Purple */
.u-btn-outline-purple {
  @include u-button-outline($g-color-purple, $g-color-purple, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-purple);
  @include u-button-outline-hover(1, 2, $g-color-purple);
  @include u-button-outline-hover(1, 3, $g-color-purple);
  @include u-button-outline-hover(1, 4, $g-color-purple);
  @include u-button-outline-hover(2, 1, $g-color-purple);
  @include u-button-outline-hover(2, 2, $g-color-purple);
}

/* Outline Button Dark Purple */
.u-btn-outline-darkpurple {
  @include u-button-outline(
    $g-color-darkpurple,
    $g-color-darkpurple,
    $g-color-white
  );
  @include u-button-outline-hover(1, 1, $g-color-darkpurple);
  @include u-button-outline-hover(1, 2, $g-color-darkpurple);
  @include u-button-outline-hover(1, 3, $g-color-darkpurple);
  @include u-button-outline-hover(1, 4, $g-color-darkpurple);
  @include u-button-outline-hover(2, 1, $g-color-darkpurple);
  @include u-button-outline-hover(2, 2, $g-color-darkpurple);
}

/* Outline Button Pink */
.u-btn-outline-pink {
  @include u-button-outline($g-color-pink, $g-color-pink, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-pink);
  @include u-button-outline-hover(1, 2, $g-color-pink);
  @include u-button-outline-hover(1, 3, $g-color-pink);
  @include u-button-outline-hover(1, 4, $g-color-pink);
  @include u-button-outline-hover(2, 1, $g-color-pink);
  @include u-button-outline-hover(2, 2, $g-color-pink);
}

/* Outline Button Orange */
.u-btn-outline-orange {
  @include u-button-outline($g-color-orange, $g-color-orange, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-orange);
  @include u-button-outline-hover(1, 2, $g-color-orange);
  @include u-button-outline-hover(1, 3, $g-color-orange);
  @include u-button-outline-hover(1, 4, $g-color-orange);
  @include u-button-outline-hover(2, 1, $g-color-orange);
  @include u-button-outline-hover(2, 2, $g-color-orange);
}

/* Outline Button Deep Orange */
.u-btn-outline-deeporange {
  @include u-button-outline(
    $g-color-deeporange,
    $g-color-deeporange,
    $g-color-white
  );
  @include u-button-outline-hover(1, 1, $g-color-deeporange);
  @include u-button-outline-hover(1, 2, $g-color-deeporange);
  @include u-button-outline-hover(1, 3, $g-color-deeporange);
  @include u-button-outline-hover(1, 4, $g-color-deeporange);
  @include u-button-outline-hover(2, 1, $g-color-deeporange);
  @include u-button-outline-hover(2, 2, $g-color-deeporange);
}

/* Outline Button Yellow */
.u-btn-outline-yellow {
  @include u-button-outline(
    $g-color-yellow,
    $g-color-gray-dark-v3,
    $g-color-gray-dark-v3
  );
  @include u-button-outline-hover(1, 1, $g-color-yellow);
  @include u-button-outline-hover(1, 2, $g-color-yellow);
  @include u-button-outline-hover(1, 3, $g-color-yellow);
  @include u-button-outline-hover(1, 4, $g-color-yellow);
  @include u-button-outline-hover(2, 1, $g-color-yellow);
  @include u-button-outline-hover(2, 2, $g-color-yellow);
}

/* Outline Button Aqua */
.u-btn-outline-aqua {
  @include u-button-outline($g-color-aqua, $g-color-aqua, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-aqua);
  @include u-button-outline-hover(1, 2, $g-color-aqua);
  @include u-button-outline-hover(1, 3, $g-color-aqua);
  @include u-button-outline-hover(1, 4, $g-color-aqua);
  @include u-button-outline-hover(2, 1, $g-color-aqua);
  @include u-button-outline-hover(2, 2, $g-color-aqua);
}

/* Outline Button Cyan */
.u-btn-outline-cyan {
  @include u-button-outline($g-color-cyan, $g-color-cyan, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-cyan);
  @include u-button-outline-hover(1, 2, $g-color-cyan);
  @include u-button-outline-hover(1, 3, $g-color-cyan);
  @include u-button-outline-hover(1, 4, $g-color-cyan);
  @include u-button-outline-hover(2, 1, $g-color-cyan);
  @include u-button-outline-hover(2, 2, $g-color-cyan);
}

/* Outline Button Teal */
.u-btn-outline-teal {
  @include u-button-outline($g-color-teal, $g-color-teal, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-teal);
  @include u-button-outline-hover(1, 2, $g-color-teal);
  @include u-button-outline-hover(1, 3, $g-color-teal);
  @include u-button-outline-hover(1, 4, $g-color-teal);
  @include u-button-outline-hover(2, 1, $g-color-teal);
  @include u-button-outline-hover(2, 2, $g-color-teal);
}

/* Outline Button Brown */
.u-btn-outline-brown {
  @include u-button-outline($g-color-brown, $g-color-brown, $g-color-white);
  @include u-button-outline-hover(1, 1, $g-color-brown);
  @include u-button-outline-hover(1, 2, $g-color-brown);
  @include u-button-outline-hover(1, 3, $g-color-brown);
  @include u-button-outline-hover(1, 4, $g-color-brown);
  @include u-button-outline-hover(2, 1, $g-color-brown);
  @include u-button-outline-hover(2, 2, $g-color-brown);
}

/* Outline Button Bluegrey */
.u-btn-outline-bluegray {
  @include u-button-outline(
    $g-color-bluegray,
    $g-color-bluegray,
    $g-color-white
  );
  @include u-button-outline-hover(1, 1, $g-color-bluegray);
  @include u-button-outline-hover(1, 2, $g-color-bluegray);
  @include u-button-outline-hover(1, 3, $g-color-bluegray);
  @include u-button-outline-hover(1, 4, $g-color-bluegray);
  @include u-button-outline-hover(2, 1, $g-color-bluegray);
  @include u-button-outline-hover(2, 2, $g-color-bluegray);
}

/* Button Facebook */
.u-btn-outline-facebook {
  @include u-button-outline(
    $g-color-facebook,
    $g-color-facebook,
    $g-color-white
  );
}

/* Button Twitter */
.u-btn-outline-twitter {
  @include u-button-outline($g-color-twitter, $g-color-twitter, $g-color-white);
}

/* Button Dribbble */
.u-btn-outline-dribbble {
  @include u-button-outline(
    $g-color-dribbble,
    $g-color-dribbble,
    $g-color-white
  );
}
