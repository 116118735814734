//
// Divider Dobule Dotted Style
//

/* Double Dotted Divider */
.u-divider-db-dotted {
  height: 5px;
  border-top: 1px dotted transparent;
  border-bottom: 1px dotted transparent;
}
