/*------------------------------------
  Carousel indicators v20
------------------------------------*/

.u-carousel-indicators-v20,
.u-carousel-indicators-v20--white {
  white-space: nowrap;

  li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      text-indent: -9999px;
      transition: border-color 0.3s ease;

      &::before,
      &::after {
        content: '';
        display: none;
      }

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        visibility: hidden;
        opacity: 0;
        transform: scale(3);
        transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0.3s;
      }

      &:hover,
      &:focus {
        border-color: $g-color-primary;
      }
    }

    &.slick-active {
      span {
        border-color: $g-color-primary;

        &::before {
          visibility: visible;
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.3s ease, transform 0.3s ease;
        }
      }
    }
  }
}

.u-carousel-indicators-v20--white {
  li {
    span {
      border: 2px solid $g-color-white;

      &::before {
        background: $g-color-primary;
        box-shadow: 0 0 1px $g-color-primary;
      }
    }
  }
}
