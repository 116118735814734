/*------------------------------------
  Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Inherit Colors */
.g-color-inherit {
  color: inherit !important;
}

/* Main Colors */
.g-color-main {
  color: $g-color-main !important;

  &--hover:hover {
    color: $g-color-main !important;
  }
}

/* Primary Colors */
.g-color-primary {
  color: $g-color-primary !important;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    color: $g-color-primary !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      color: $g-color-primary !important;
    }
  }

  &-opacity {
    &-0_3 {
      color: rgba($g-color-primary, 0.3) !important;
    }

    &-0_4 {
      color: rgba($g-color-primary, 0.4) !important;
    }
  }

  &--active.active {
    color: $g-color-primary !important;
  }

  &--parent-active {
    .active & {
      color: $g-color-primary !important;
    }
  }

  //Pseudo-elements color // Z
  &--before::before,
  &--after::after {
    color: $g-color-primary;
  }
}

/* Secondary Colors */
.g-color-secondary {
  color: $g-color-secondary !important;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    color: $g-color-secondary !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      color: $g-color-secondary !important;
    }
  }
}

/* Black Colors */
.g-color-black {
  color: $g-color-black !important;

  &--hover:hover {
    color: $g-color-black !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      color: $g-color-black !important;
    }
  }

  &--active.active {
    color: $g-color-black !important;
  }

  &-opacity {
    &-0_1 {
      color: rgba($g-color-black, 0.1) !important;
    }
    &-0_3 {
      color: rgba($g-color-black, 0.3) !important;
    }
    &-0_5 {
      color: rgba($g-color-black, 0.5) !important;
    }
    &-0_6 {
      color: rgba($g-color-black, 0.6) !important;
    }
    &-0_7 {
      color: rgba($g-color-black, 0.7) !important;
    }
    &-0_8 {
      color: rgba($g-color-black, 0.8) !important;

      &--child {
        * {
          color: rgba($g-color-black, 0.8) !important;
        }
      }
    }
    &-0_9 {
      color: rgba($g-color-black, 0.9) !important;
    }
  }
}

/* White Colors */
.g-color-white {
  color: $g-color-white !important;

  &--opened-menu:not(.collapsed) {
    color: $g-color-white !important;
  }

  .u-block-hover:hover &--hover,
  &--hover:hover {
    color: $g-color-white !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      color: $g-color-white !important;
    }
  }

  &--active.active {
    color: $g-color-white !important;
  }

  &--parent-active {
    .active & {
      color: $g-color-white !important;
    }
  }

  &--child {
    * {
      color: $g-color-white !important;
    }
  }

  &-opacity {
    &-0_1 {
      color: rgba($g-color-white, 0.1) !important;
    }
    &-0_2 {
      color: rgba($g-color-white, 0.2) !important;
    }
    &-0_3 {
      color: rgba($g-color-white, 0.3) !important;
    }
    &-0_5,
    &-0_5--hover:hover {
      color: rgba($g-color-white, 0.5) !important;
    }
    &-0_6 {
      color: rgba($g-color-white, 0.6) !important;

      &--parent-hover {
        .g-parent:hover & {
          color: rgba($g-color-white, 0.6) !important;
        }
      }
    }
    &-0_7,
    &-0_7--hover:hover {
      color: rgba($g-color-white, 0.7) !important;
    }
    &-0_75 {
      color: rgba($g-color-white, 0.75) !important;
    }
    &-0_8 {
      color: rgba($g-color-white, 0.8) !important;

      &--child {
        * {
          color: rgba($g-color-white, 0.8) !important;
        }
      }
    }
    &-0_9,
    &-0_9--hover:hover {
      color: rgba($g-color-white, 0.9) !important;
    }
  }
}

.u-block-hover:hover .g-color-white-opacity-0_7--hover {
  color: rgba($g-color-white, 0.7) !important;
}

/* Gray Colors */
.g-color-gray {
  // Gray Light
  &-light {
    &-v1 {
      color: $g-color-gray-light-v1 !important;

      &--hover:hover {
        color: $g-color-gray-light-v1 !important;
      }
    }
    &-v2 {
      color: $g-color-gray-light-v2 !important;

      &--hover:hover {
        color: $g-color-gray-light-v2 !important;
      }
    }
    &-v3 {
      color: $g-color-gray-light-v3 !important;

      &--hover:hover {
        color: $g-color-gray-light-v3 !important;
      }
    }
    &-v4 {
      color: $g-color-gray-light-v4 !important;

      &--hover:hover {
        color: $g-color-gray-light-v4 !important;
      }

      &-opacity {
        &-0_6 {
          color: rgba($g-color-gray-light-v4, 0.6) !important;
        }
      }
    }
    &-v5 {
      color: $g-color-gray-light-v5 !important;

      &--hover:hover {
        color: $g-color-gray-light-v5 !important;
      }
    }
  }

  // Gray Dark
  &-dark {
    &-v1 {
      color: $g-color-gray-dark-v1 !important;

      &--hover:hover {
        color: $g-color-gray-dark-v1 !important;
      }
    }
    &-v2 {
      color: $g-color-gray-dark-v2 !important;

      &--hover:hover {
        color: $g-color-gray-dark-v2 !important;
      }

      &-opacity {
        &-0_75 {
          color: rgba($g-color-gray-dark-v2, 0.75) !important;
        }
      }
    }
    &-v3 {
      color: $g-color-gray-dark-v3 !important;

      &--hover:hover {
        color: $g-color-gray-dark-v3 !important;
      }
    }
    &-v4 {
      color: $g-color-gray-dark-v4 !important;

      &--hover:hover {
        color: $g-color-gray-dark-v4 !important;
      }
    }
    &-v5 {
      color: $g-color-gray-dark-v5 !important;

      &--hover:hover {
        color: $g-color-gray-dark-v5 !important;
      }

      &--parent-hover {
        .g-parent:hover & {
          color: $g-color-gray-dark-v5 !important;
        }
      }
    }
  }
}

/* Complementary Colors
------------------------------------*/
/* Color Green */
.g-color-green {
  color: $g-color-green !important;

  &--hover:hover {
    color: $g-color-green !important;
  }
}

/* Color Blue */
.g-color-blue {
  color: $g-color-blue !important;

  &--hover:hover {
    color: $g-color-blue !important;
  }

  &-dark-v1 {
    color: $g-color-blue-dark-v1 !important; // Z
  }
}

/* Color Light Blue */
.g-color-lightblue {
  color: $g-color-lightblue !important;

  &--hover:hover {
    color: $g-color-lightblue !important;
  }
}

.g-color-lightblue-v1 {
  color: $g-color-lightblue-v1 !important;

  &--hover:hover {
    color: $g-color-lightblue-v1 !important;
  }
}

/* Color Dark Blue */
.g-color-darkblue {
  color: $g-color-darkblue !important;

  &--hover:hover {
    color: $g-color-darkblue !important;
  }
}

/* Color Indigo */
.g-color-indigo {
  color: $g-color-indigo !important;

  &--hover:hover {
    color: $g-color-indigo !important;
  }
}

/* Color Red */
.g-color-red {
  color: $g-color-red !important;

  &--hover:hover {
    color: $g-color-red !important;
  }

  &--parent-hover {
    *:hover > & {
      color: $g-color-red !important;
    }
  }
}

/* Color Light Red */
.g-color-lightred {
  color: $g-color-lightred !important;

  &--hover:hover {
    color: $g-color-lightred !important;
  }
}

/* Color Dark Red */
.g-color-darkred {
  color: $g-color-darkred !important;

  &--hover:hover {
    color: $g-color-darkred !important;
  }
}

/* Color Purple */
.g-color-purple {
  color: $g-color-purple;

  &--hover:hover {
    color: $g-color-purple !important;
  }

  &-dark-v1 {
    color: $g-color-purple-dark-v1 !important; // Z
  }
}

/* Color Dark Purple */
.g-color-darkpurple {
  color: $g-color-darkpurple !important;

  &--hover:hover {
    color: $g-color-darkpurple !important;
  }
}

/* Color Pink */
.g-color-pink {
  color: $g-color-pink;

  &--hover:hover {
    color: $g-color-pink !important;
  }

  &-dark-v1 {
    color: $g-color-pink-dark-v1 !important; // Z
  }
}

/* Color Orange */
.g-color-orange {
  color: $g-color-orange !important;

  &--hover:hover {
    color: $g-color-orange !important;
  }
}

/* Color Deep Orange */
.g-color-deeporange {
  color: $g-color-deeporange !important;

  &--hover:hover {
    color: $g-color-deeporange !important;
  }
}

/* Color Yellow */
.g-color-yellow {
  color: $g-color-yellow !important;

  &--hover:hover {
    color: $g-color-yellow !important;
  }
}

/* Color Aqua */
.g-color-aqua {
  color: $g-color-aqua;

  &--hover:hover {
    color: $g-color-aqua !important;
  }

  &-dark-v1 {
    color: $g-color-aqua-dark-v1 !important; // Z
  }
}

/* Color Cyan */
.g-color-cyan {
  color: $g-color-cyan !important;

  &--hover:hover {
    color: $g-color-cyan !important;
  }
}

/* Color Teal */
.g-color-teal {
  color: $g-color-teal !important;

  &--hover:hover {
    color: $g-color-teal !important;
  }
}

/* Color Brown */
.g-color-brown {
  color: $g-color-brown !important;

  &--hover:hover {
    color: $g-color-brown !important;
  }
}

/* Color Blue Gray */
.g-color-bluegray {
  color: $g-color-bluegray !important;

  &--hover:hover {
    color: $g-color-bluegray !important;
  }
}
