$primary: #990033;
$primary-light: #e3004c;
$primary-hover: #cf0045;
$red: #ff0000;
$background-b: #61a4ca;
$light-grayish-blue: #ced4da;
$gray: #bebebe;
$light-gray: #d3d3d3;
$very-dark-gray: #555;
$white: #fff;
$black: #000;
$text-grey: #565555;

/*legend color*/

$bg-orange-color: #ffdfa3;
$bg-orange2-color: #fbbd56;
$bg-orange3-color: #f49822;
$bg-red-color: #ec661a;
$bg-red2-color: #cc2617;
$bg-green-color: #f6f7e6;
$bg-green2-color: #fff6db;
$bg-green3-color: #ccd96a;
$bg-green4-color: #e0de41;
$bg-green5-color: #adbf54;
$bg-green6-color: #e3e36d;
$bg-green7-color: #738039;
$bg-green8-color: #c4c497;
$bg-green9-color: #adbf54;
$bg-blue-color: #618eb0;
$bg-blue2-color: #c0e4fa;
$bg-brown-color: #e6c195;
$bg-brown2-color: #d9b78d;
$bg-brown3-color: #b39774;
$bg-brown4-color: #d7b09e;
$bg-brown5-color: #bfab8c;
$bg-brown6-color: #b3a176;
$bg-brown7-color: #7a6f51;
$bg-pink-color: #f7c1b7;
$bg-purple-color: #bfbfbf;
$bg-black-color: #8f8d88;
$bg-grey-color: #ccc;
