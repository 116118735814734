//
// Margins
//

@mixin margin-spaces($media-type) {
  $media-value: 0;

  @if $media-type == xs {
    $media-value: 0;
    $media-type: '';
  } @else if $media-type == sm {
    $media-value: $g-sm;
    $media-type: --sm;
  } @else if $media-type == md {
    $media-value: $g-md;
    $media-type: --md;
  } @else if $media-type == lg {
    $media-value: $g-lg;
    $media-type: --lg;
  } @else {
    /* P */
    $media-value: $g-xl;
    $media-type: --xl;
  }

  @media (min-width: #{$media-value}) {
    // Zeroing
    .g-ma-0#{$media-type} {
      margin: 0 !important;
    }
    .g-mx-0#{$media-type} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .g-my-0#{$media-type} {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .g-ml-0#{$media-type} {
      margin-left: 0 !important;
    }
    .g-mr-0#{$media-type} {
      margin-right: 0 !important;
    }
    .g-mt-0#{$media-type} {
      margin-top: 0 !important;
    }
    .g-mb-0#{$media-type} {
      margin-bottom: 0 !important;
    }

    // Margin X
    $i: 1;
    @while $i < 11 {
      .g-mx-#{$i}#{$media-type} {
        @include px-to-rem(margin-left, $i * 1px !important);
        @include px-to-rem(margin-right, $i * 1px !important);
      }
      $i: $i + 1;
    }
    $i: 10;
    @while $i < 101 {
      .g-mx-#{$i}#{$media-type} {
        @include px-to-rem(margin-left, $i * 1px !important);
        @include px-to-rem(margin-right, $i * 1px !important);
      }
      $i: $i + 5;
    }

    // Margin Y
    $i: 1;
    @while $i < 11 {
      .g-my-#{$i}#{$media-type} {
        @include px-to-rem(margin-top, $i * 1px !important);
        @include px-to-rem(margin-bottom, $i * 1px !important);
      }
      $i: $i + 1;
    }
    $i: 10;
    @while $i < 101 {
      .g-my-#{$i}#{$media-type} {
        @include px-to-rem(margin-top, $i * 1px !important);
        @include px-to-rem(margin-bottom, $i * 1px !important);
      }
      $i: $i + 5;
    }

    // Margin Top
    $i: 1;
    @while $i < 21 {
      .g-mt-#{$i}#{$media-type} {
        @include px-to-rem(margin-top, $i * 1px !important);
      }
      .g-mt-minus-#{$i}#{$media-type} {
        @include px-to-rem(margin-top, -($i * 1px) !important);
      }
      $i: $i + 1;
    }
    $i: 10;
    @while $i < 171 {
      .g-mt-#{$i}#{$media-type} {
        @include px-to-rem(margin-top, $i * 1px !important);
      }
      .g-mt-minus-#{$i}#{$media-type} {
        @include px-to-rem(margin-top, -($i * 1px) !important);
      }
      $i: $i + 5;
    }

    // Margin Bottom
    $i: 1;
    @while $i < 21 {
      .g-mb-#{$i}#{$media-type} {
        @include px-to-rem(margin-bottom, $i * 1px !important);
      }
      .g-mb-minus-#{$i}#{$media-type} {
        @include px-to-rem(margin-bottom, -($i * 1px) !important);
      }
      $i: $i + 1;
    }
    $i: 10;
    @while $i < 171 {
      .g-mb-#{$i}#{$media-type} {
        @include px-to-rem(margin-bottom, $i * 1px !important);
      }
      $i: $i + 5;
    }

    // Margin Left
    $i: 1;
    @while $i < 11 {
      .g-ml-#{$i}#{$media-type} {
        @include px-to-rem(margin-left, $i * 1px !important);
      }
      .g-ml-minus-#{$i}#{$media-type} {
        @include px-to-rem(margin-left, -($i * 1px) !important);
      }
      $i: $i + 1;
    }
    $i: 5;
    @while $i < 51 {
      .g-ml-#{$i}#{$media-type} {
        @include px-to-rem(margin-left, $i * 1px !important);
      }
      .g-ml-minus-#{$i}#{$media-type} {
        @include px-to-rem(margin-left, -($i * 1px) !important);
      }
      $i: $i + 5;
    }

    // Margin Right
    $i: 1;
    @while $i < 11 {
      .g-mr-#{$i}#{$media-type} {
        @include px-to-rem(margin-right, $i * 1px !important);
      }
      .g-mr-minus-#{$i}#{$media-type} {
        @include px-to-rem(margin-right, -($i * 1px) !important);
      }
      $i: $i + 1;
    }
    $i: 5;
    @while $i < 51 {
      .g-mr-#{$i}#{$media-type} {
        @include px-to-rem(margin-right, $i * 1px !important);
      }
      $i: $i + 5;
    }
  }
}
