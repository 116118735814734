@import "styles/variables.scss";
.cityContainer {
  & > div:last-child {
    margin-bottom: 0;
  }
}
.citiesBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  a {
    justify-content: space-between;
    text-decoration: none !important;
  }
}

.textPositionLeftBlock {
  width: 100%;
  height: 730px;
  overflow: scroll;
  overflow-x: hidden;
}
.textPositionLeftBlockMarket {
  width: 100%;
  border-right: 1px dotted $black;
}

.leftBlockMarketDetail {
  width: 100%;
}

.textPositionLeftBlockProperty {
  max-width: 100%;
  border-right: 1px dotted $black;
}

.detailHouseView {
  width: 50%;
}
.houseDetailInfo {
  background-color: $primary;
  height: 250px;
  color: $white;
  padding-right: 30px;
  padding-bottom: 15px;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  & > div {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
}
.iframeBlockProperty {
  padding-left: 0 !important;
}

.marketContainer {
  flex: 1 0 auto;
  z-index: 999;
  background-color: $white;
}
.brandImage {
  display: flex;
  margin: 0 auto;
  cursor: pointer;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  background-color: $white;
}
.marketBlock {
  display: flex;
  margin-bottom: 75px;

  .offersContainer {
    width: 607px;
    height: 730px;

    @media screen and (max-width: 1200px) {
      width: 780px;
    }
  }
}
.Vivawest {
  width: 800px !important;

  @media screen and (max-width: 770px) {
    width: 100% !important;
  }
}
.info {
  justify-content: center;
  align-items: center;
  img {
    display: inline-flex;
    margin: 0 !important;
  }
  p {
    font-weight: bold;
    font-size: 24px;
    margin: 0;
  }
}
.detail {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 0;
}

.range {
  height: 50px;
  width: 100%;
  background: linear-gradient(to right, $light-gray, $black);
}
.iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
.detailHouse {
  height: 30px;
  display: flex;
  align-items: center;
}
.house {
  height: 36px;
  display: flex;
  align-items: center;
}
.marketImage {
  width: 51px;
  height: 61px;
}

.ImgArrowRight {
  position: absolute;
  z-index: 2;
  top: 200px;
  right: -50px;
  cursor: pointer;
  transform: rotate(180deg);
}

.ImgArrowLeft {
  position: absolute;
  z-index: 2;
  top: 200px;
  left: -50px;
  cursor: pointer;
}

.propertyTags {
  margin-top: 100px;
}

.myLink {
  display: inline-flex;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  background-color: $primary;
  color: $white;
  font-size: 32px;
  padding: 10px 50px;
  position: relative;
  //height: 80px;
  & > svg {
    position: absolute;
    top: 5px;
    right: 15px;
    height: 20px;
  }
}
.chartContainer {
  display: flex;
  width: 85%;
  flex-wrap: wrap;
}
.imageDetailMarket {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.containerRenting {
  display: flex;
  flex-wrap: wrap;
}
.color {
  color: $primary;
}
@media screen and (max-width: 990px) {
  .containerRenting {
    & > div {
      padding: 30px !important;
    }
    & > div:nth-child(1) {
      padding-bottom: 30px !important;
    }
  }
  .chartContainer {
    & > div:last-child {
      padding-left: 0;
    }
  }
}
@media screen and (max-width: 770px) {
  .textPositionLeftBlockProperty {
    padding-right: 0 !important;
  }
  .textPositionLeftBlockMarket {
    padding-right: 0 !important;
  }
  .iframeBlock {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  .ImgArrowLeft {
    left: -10px;
  }
  .ImgArrowRight {
    right: 0;
  }
  .show {
    transition: transform 0.5s;
    transform: translate3d(0, 0, 0);
  }
  .hide {
    transition: transform 0.5s;
    transform: translate3d(0, -225px, 0);
  }
  .detailHouseContainer {
    height: 1050px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .detailHouseView {
      width: 100%;
    }
    .iframeBlockDetailHouse {
      width: 100% !important;
      height: 400px;
    }
  }
}

@media screen and (max-width: 992px) {
  .marketBlock {
    display: flex;
    flex-direction: column;
    .textPositionLeftBlockMarket {
      width: 100% !important;
      max-width: 100%;
      border-right: none;
    }
    .iframeBlock {
      overflow: hidden;
      width: 100% !important;
      max-width: 100%;
      height: 400px;
    }
    .iframeBlockProperty {
      width: 100% !important;
      margin-top: 120px;
    }
    .textPositionLeftBlockProperty {
      width: 100% !important;
      border-right: none;
    }
    .textPositionLeftBlock {
      margin-bottom: 1.5rem !important;
    }
    .offersContainer {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .marketBlock {
    flex-direction: column;
  }
  .textPositionLeftBlock {
    width: 100% !important;
  }
  .iframeBlock {
    width: 100% !important;
    height: 400px;
  }
  .iframeBlockProperty {
    height: 400px;
  }
  .marketBlock {
    .offersContainer {
      width: 100%;
    }
  }
  .propertyTags {
    margin-top: 0;
  }
}
@media screen and (max-width: 420px) {
  .offersContainer {
    height: 100% !important;
  }
  .textPositionLeftBlock {
    margin-bottom: 0 !important;
    height: 100%;
    overflow: inherit !important;
  }
  .iframeBlockProperty {
    margin-top: 0 !important;
  }
  .detailHouseView {
    height: 100%;
  }
  .marketHeadline {
    word-break: break-all;
  }
}
