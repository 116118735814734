/*------------------------------------
  Positions
------------------------------------*/
.g-pos {
  &-rel {
    position: relative !important;
  }

  &-abs {
    position: absolute !important;
  }

  &-stc {
    position: static !important;
  }

  &-fix {
    position: fixed !important;
  }
}

@media (min-width: $g-sm) {
  .g-pos {
    &-rel--sm {
      position: relative !important;
    }

    &-fix--sm {
      position: fixed !important;
    }

    &-abs--sm {
      position: absolute !important;
    }

    &-stc--sm {
      position: static !important;
    }
  }
}

@media (min-width: $g-md) {
  .g-pos {
    &-rel--md {
      position: relative !important;
    }

    &-fix--md {
      position: fixed !important;
    }

    &-abs--md {
      position: absolute !important;
    }

    &-stc--sm {
      position: static !important;
    }
  }
}

@media (min-width: $g-lg) {
  .g-pos {
    &-rel--lg {
      position: relative !important;
    }

    &-fix--lg {
      position: fixed !important;
    }

    &-abs--lg {
      position: absolute !important;
    }

    &-stc--lg {
      position: static !important;
    }
  }
}
