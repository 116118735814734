/*------------------------------------
  Typography Line Height
------------------------------------*/
.g-line-height {
  &-0 {
    line-height: 0 !important;
  }
  &-0_7 {
    line-height: 0.7 !important;
  }
  &-0_8 {
    line-height: 0.8 !important;
  }
  &-0_9 {
    line-height: 0.9 !important;
  }
  &-1 {
    line-height: 1 !important;
  }
  &-1_1 {
    line-height: 1.1 !important;
  }
  &-1_2 {
    line-height: 1.2 !important;
  }
  &-1_3 {
    line-height: 1.3 !important;
  }
  &-1_4 {
    line-height: 1.4 !important;
  }
  &-1_5 {
    line-height: 1.5 !important;
  }
  &-1_6 {
    line-height: 1.6 !important;
  }
  &-1_8 {
    line-height: 1.8 !important;
  }
  &-2 {
    line-height: 2 !important;
  }
}
