/*------------------------------------
  Badges v1
------------------------------------*/

[class*='u-badge-v1'] {
  background-color: $g-color-gray-light-v3;
  box-sizing: content-box;
}

.u-badge-v1 {
  @include px-to-rem('min-width', 18px);
  @include px-to-rem('min-height', 18px);
  @include px-to-rem('line-height', 18px);
  @include px-to-rem('padding', 3px);

  &.g-brd-around {
    @include px-to-rem('line-height', 16px);
  }

  &--xs {
    @include px-to-rem('min-width', 14px);
    @include px-to-rem('min-height', 14px);
    @include px-to-rem('line-height', 14px);
    @include px-to-rem('font-size', 10px);
    @include px-to-rem('padding', 2px);

    &.g-brd-around {
      @include px-to-rem('line-height', 12px);
    }
  }

  &--sm {
    @include px-to-rem('min-width', 16px);
    @include px-to-rem('min-height', 16px);
    @include px-to-rem('line-height', 16px);
    @include px-to-rem('font-size', 11px);
    @include px-to-rem('padding', 2px);

    &.g-brd-around {
      @include px-to-rem('line-height', 14px);
    }
  }

  &--md {
    @include px-to-rem('min-width', 18px);
    @include px-to-rem('min-height', 18px);
    @include px-to-rem('line-height', 18px);
    @include px-to-rem('font-size', 13px);
    @include px-to-rem('padding', 3px);

    &.g-brd-around {
      @include px-to-rem('line-height', 16px);
    }
  }

  &--lg {
    @include px-to-rem('min-width', 20px);
    @include px-to-rem('min-height', 20px);
    @include px-to-rem('line-height', 20px);
    @include px-to-rem('font-size', 15px);
    @include px-to-rem('padding', 3px);

    &.g-brd-around {
      @include px-to-rem('line-height', 18px);
    }
  }

  &--xl {
    @include px-to-rem('min-width', 26px);
    @include px-to-rem('min-height', 26px);
    @include px-to-rem('line-height', 26px);
    @include px-to-rem('font-size', 17px);
    @include px-to-rem('padding', 3px);

    &.g-brd-around {
      @include px-to-rem('line-height', 24px);
    }
  }
}
