/*------------------------------------
  Tables
------------------------------------*/

[class*='text'][class*='center'] {
  td,
  th {
    text-align: center;
  }
}

.u-table {
  &--v1 {
    td,
    th {
      padding: 8px;
    }

    thead {
      th {
        border: {
          bottom: {
            width: 1px;
          }
        }
      }
    }
  }

  &--v2 {
    td,
    th {
      padding: 15px;
    }

    thead {
      th {
        background: {
          color: inherit;
        }
        border: {
          bottom: {
            width: 1px;
          }
        }
      }
    }
  }
}

.g-col {
  &-border {
    &-top-0 {
      td,
      th {
        border: {
          top: {
            width: 0;
          }
        }
      }
    }

    &-side-0 {
      td,
      th {
        border: {
          left: {
            width: 0;
          }
          right: {
            width: 0;
          }
        }
      }
    }
  }
}
