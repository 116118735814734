/*------------------------------------
  Info Block v5-5
------------------------------------*/
.info-v5-5__header::after,
.info-v5-5__content::after {
  transition: inherit;
}

.info-v5-5__content::after {
  opacity: 0;
}

.info-v5-5 {
  &:hover .info-v5-5__header::after,
  &:hover .info-v5-5__content::after {
    opacity: 0.8;
  }
}
