/*------------------------------------
  Info Block v5-1
------------------------------------*/
/* Title */
.info-v5-1__title {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    display: block;
    width: 3000%;
    margin-left: 15px;
    border-bottom: 1px solid $g-color-gray-light-v4;
  }
}
