/*------------------------------------
  Time Lines v1
------------------------------------*/
.u-timeline-v1 {
  position: relative;
  border: {
    width: 1px;
    style: solid;
    color: $g-color-gray-light-v4;
  }

  // Timeline Wrap
  &-wrap::before {
    left: 0;
    width: 1px;
    height: 100%;
    background-color: $g-color-gray-light-v5;
    margin-left: 20px;
  }

  // Timeline Icon
  &__icon {
    position: absolute;
    top: 30px;
    font-size: 20px;
    z-index: 3;

    .g-orientation-bottom &,
    .g-orientation-right &,
    .g-orientation-left & {
      left: 0;
    }
  }
}

@media (min-width: $g-md) {
  .u-timeline-v1 {
    // Timeline Wrap
    &-wrap {
      &:not([class*='--horizontal']) {
        &::before {
          left: 50%;
          margin-left: -2px;
        }
      }

      &--horizontal {
        &::before {
          top: auto;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 3px;
          margin-left: 0;
          margin-top: -2px;
        }
      }
    }

    // Timeline Icon
    &__icon {
      .g-orientation-bottom & {
        top: 100%;
        left: 0;
        transform: translateX(-50%) translateY(-50%);
      }

      .g-orientation-left & {
        right: 100%;
        left: auto;
      }

      .g-orientation-right & {
        right: auto;
        left: 100%;
      }
    }
  }
}
