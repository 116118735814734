/*------------------------------------
  Heading-v2-6
------------------------------------*/
%u-heading-v2-6 {
  @include px-to-rem(width, 30px);
  border-top-width: 1px;
}
.u-heading-v2-6 {
  &--bottom::after {
    @extend %u-heading-v2;
    @extend %u-heading-v2-6;
    @include px-to-rem(margin-top, 10px);
  }
  &--top::before {
    @extend %u-heading-v2;
    @extend %u-heading-v2-6;
    @include px-to-rem(margin-bottom, 10px);
  }
}
