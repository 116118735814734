/*------------------------------------
  Slide Effect v1
------------------------------------*/
.u-icon-sliding--hover i::before {
  display: block;
}

.u-icon-sliding--hover:hover i::before,
.u-icon-block--hover:hover .u-icon-sliding--hover i::before {
  animation: toTopFromBottom 0.3s forwards;
}

@keyframes toTopFromBottom {
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}
