/*------------------------------------
  Navigation Dropdown With Columns
------------------------------------*/

@media (min-width: $g-lg) {
  [class*='u-dropdown-col-'] {
    column-gap: 1px;

    .hs-sub-menu-opened & {
      column-rule: 1px solid $g-color-gray-light-v5;
    }

    & > * {
      min-width: 200px;
      -webkit-column-break-inside: avoid;
      column-break-inside: avoid;
    }
  }
  .u-dropdown-col-2 {
    columns: 2;
  }
  .u-dropdown-col-3 {
    columns: 3;
  }
}
