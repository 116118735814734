@import "styles/variables.scss";
:root {
  --defaultFonts: 'Roboto', sans-serif;
}

* {
  font-family: var(--defaultFonts);
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

header {
  flex: 0 0 auto;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::selection {
  background: $primary !important; /* Safari */
}
::-moz-selection {
  background: $primary !important; /* Firefox */
}

.highcharts-credits {
  display: none;
}

.chartContainer {
  width: 100%;
}
.highcharts-root {
  width: 100% !important;
}

.highcharts-axis-labels {
  transform: translate(-10px, 0);
}
.highcharts-data-labels {
  & > .highcharts-label:last-child {
    display: none;
  }
  & > .highcharts-label:nth-of-type(6) {
    display: none;
  }
  & > .highcharts-label:nth-of-type(2),
  .highcharts-label:nth-of-type(4),
  .highcharts-label:nth-of-type(5),
  .highcharts-label:nth-of-type(7) {
    & > text {
      fill: transparent !important;
      & > tspan:nth-of-type(1) {
        fill: $white !important;
        stroke: transparent !important;
      }
    }
  }
}
.highcharts-legend-item {
  .highcharts-graph {
    stroke-width: 10;
  }
}

/* Slider */
.slick-dots {
  position: absolute !important;
  bottom: -55px !important;
}
.slick-list {
  margin: 0 auto !important;
}

.myStyle {
  .slick-slide {
    width: 300px !important;
  }
  .slick-track {
    justify-content: center;
  }
}
.sliderHorizontal {
  width: 90%;
  margin: 0 auto !important;
  position: relative;
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    margin-right: 20px;
    width: 300px !important;
  }
  .carousel {
    width: 100%;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* slider react bootstrap dots*/
.carousel-indicators .active {
  background-color: $primary !important;
}

.carouselMy {
  position: relative;
}
.carouselMy:before,
.carouselMy:after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 100px;
  top: 0;
  height: 100%;
  pointer-events: none; /*makes the linkes behind clickable*/
}

.form-control:focus {
  border-color: #990033 !important;
  box-shadow: none !important;
}
/*cookie*/

.react-cookie-banner {
  .cookie-message {
    padding-top: 10px;
    line-height: 10px !important;
  }
}
.button-close {
  display: none !important;
}
/*legend map*/
.hide-legend {
  display: none;
}

.legend {
  display: flex;
  flex: 1 1 auto;
  padding: 1.5625rem 1.25rem 1.25rem 1.25rem;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  overflow-y: scroll;
  min-height: 214px;
  max-height: 500px;
  button {
    background-color: inherit;
    border: none;
    text-decoration: underline;
    padding: 0;
    text-align: left;
    margin-top: 0.5rem;
  }
  button:hover {
    cursor: pointer;
  }
}

.legend p {
  margin-bottom: 0.5rem;
}
.legend p:nth-of-type(5) {
  margin-bottom: 0;
}
.legend h4 {
  line-height: 1.5;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
}
.legend h4:nth-of-type(1) {
  margin-top: 0;
}

.legend span {
  position: relative;
  bottom: 3px;
}

.legend i {
  display: inline-flex;
  height: 13px;
  width: 40px;
  margin-right: 5px;
  border: 1px solid #737373;
}

.legend i.icon {
  background-size: 18px;
  background-color: rgba(255, 255, 255, 1);
}
.bg-orange-color {
  background-color: $bg-orange-color;
}
.bg-orange2-color {
  background-color: $bg-orange2-color;
}
.bg-orange3-color {
  background-color: $bg-orange3-color;
}
.bg-red-color {
  background-color: $bg-red-color;
}
.bg-red2-color {
  background-color: $bg-red2-color;
}
.bg-green-color {
  background-color: $bg-green-color;
}
.bg-green2-color {
  background-color: $bg-green2-color;
}
.bg-green3-color {
  background-color: $bg-green3-color;
}
.bg-green4-color {
  background-color: $bg-green4-color;
}
.bg-green5-color {
  background-color: $bg-green5-color;
}
.bg-green6-color {
  background-color: $bg-green6-color;
}
.bg-green7-color {
  background-color: $bg-green7-color;
}
.bg-green8-color {
  background-color: $bg-green8-color;
}
.bg-green9-color {
  background-color: $bg-green9-color;
}
.bg-blue-color {
  background-color: $bg-blue-color;
}
.bg-blue2-color {
  background-color: $bg-blue2-color;
}
.bg-brown-color {
  background-color: $bg-brown-color;
}
.bg-brown2-color {
  background-color: $bg-brown2-color;
}
.bg-brown3-color {
  background-color: $bg-brown3-color;
}
.bg-brown4-color {
  background-color: $bg-brown4-color;
}
.bg-brown5-color {
  background-color: $bg-brown5-color;
}
.bg-brown6-color {
  background-color: $bg-brown6-color;
}
.bg-brown7-color {
  background-color: $bg-brown7-color;
}
.bg-pink-color {
  background-color: $bg-pink-color;
}
.bg-purple-color {
  background-color: $bg-purple-color;
}
.bg-black-color {
  background-color: $bg-black-color;
}
.bg-grey-color {
  background-color: $bg-grey-color;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.leaflet-control-command-interior {
  button {
    border-radius: 25px;
    padding: 4px 16px;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: #343a40;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    font-weight: 400;
    margin-bottom: 8px;
    border: 1px solid transparent;
  }
  button:focus {
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgb(82 88 93 / 50%);
  }
  button:hover {
    background-color: #23272b;
  }
}

#__next
  > div
  > div.Layout_content__ANc3Y
  > div.container
  > div:nth-child(4)
  > div
  > div
  > div:nth-child(1)
  > button:hover,
#__next
  > div
  > div.Layout_content__ANc3Y
  > div.container
  > div:nth-child(4)
  > div
  > div
  > div:nth-child(2)
  > button:hover,
#__next
  > div
  > div.Layout_content__ANc3Y
  > div.container
  > div:nth-child(4)
  > div
  > div
  > div:nth-child(3)
  > button:hover,
#__next
  > div
  > div.Layout_content__ANc3Y
  > div.container
  > div:nth-child(4)
  > div
  > div
  > div:nth-child(4)
  > button:hover {
  color: white;
  background: #990033;
  border-color: #990033;
}

.ant-tabs-tab {
  color: gray !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: gray !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  position: absolute;
  background: maroon;
  pointer-events: none;
}
