/*------------------------------------
  Time Lines v2
------------------------------------*/
@media (min-width: $g-md) {
  .u-timeline-v2 {
    // Timeline Wrap
    &-wrap::before {
      left: 25%;
      width: 3px;
      height: 100%;
      margin-left: -1px;
      background-color: $g-color-gray-light-v5;
    }

    // Timeline Icon
    &__icon {
      font-size: 16px;
      position: absolute;

      .g-orientation-right & {
        left: 100%;
        transform: translateX(-50%);
      }

      .g-orientation-left & {
        right: 100%;
        transform: translateX(50%);
      }

      .g-orientation-bottom & {
        top: 100%;
        transform: translateY(-50%);
      }
    }
  }
}
