/*------------------------------------
  Typography Font Family
------------------------------------*/
// .g-font-primary {
//   font-family: $g-font-primary;
// }
.g-font-secondary {
  font-family: $g-font-secondary;
}
.g-font-code {
  font-family: $g-font-code;
}
.g-font-niconne {
  font-family: $g-font-niconne;
}
