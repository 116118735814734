/* Hover v1-4 */
.u-btn-hover-v1-4::after {
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
}
.u-btn-hover-v1-4:hover::after {
  width: 100%;
}
