/*------------------------------------
  Icons Styles
------------------------------------*/
//
// Common Styles
//

.u-icon-v1,
.u-icon-v2,
.u-icon-v3,
.u-icon-v4 {
  position: relative;
  display: inline-block;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
.u-icon-v1:hover,
.u-icon-v2:hover,
.u-icon-v3:hover,
.u-icon-v4:hover {
  text-decoration: none;
}
.u-icon-v1::before,
.u-icon-v2::before,
.u-icon-v3::before {
  display: block;
}
.u-icon-v1 > i,
.u-icon-v2 > i,
.u-icon-v3 > i,
.u-icon-v4 > span > i {
  position: relative;
  top: 50%;
  display: block;
  transform: translateY(-50%);
  z-index: 2;
}

[class*='u-icon-v'] {
  & .u-line-icon-pro {
    transform: translateY(-45%);
  }
}

//
// Rotattion Style
//

.u-icon-rotation {
  transform: rotate(45deg);

  & .u-icon__elem {
    transform: rotate(-45deg) translate(15%, -30%);
  }
  & .u-line-icon-pro {
    transform: rotate(-45deg) translate(25%, -30%);
  }
}
