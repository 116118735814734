/*------------------------------------
  Nonstandard Background v5
------------------------------------*/

[class*='u-ns-bg-v5'] {
  position: relative;

  .u-ns-bg-before {
    position: absolute;
    z-index: 2;
  }

  &.g-bg-primary {
    svg {
      polygon,
      path {
        fill: $g-color-primary;
      }
    }
  }

  &.g-bg-white {
    svg {
      polygon,
      path {
        fill: $g-color-white;
      }
    }
  }

  &.g-bg-gray-light-v2 {
    svg {
      polygon,
      path {
        fill: $g-color-gray-light-v2;
      }
    }
  }

  &.g-bg-black {
    svg {
      polygon,
      path {
        fill: $g-color-black;
      }
    }
  }
}

.u-ns-bg-v5 {
  &-top,
  &-bottom {
    .u-ns-bg-before {
      width: 100%;
    }

    svg {
      width: 100%;
    }
  }

  &-left,
  &-right {
    .u-ns-bg-before {
      height: 100%;
    }

    svg {
      height: 100%;
      vertical-align: middle;
    }
  }

  &-top {
    .u-ns-bg-before {
      bottom: 100%;
    }

    svg {
      vertical-align: bottom;
    }
  }

  &-bottom {
    .u-ns-bg-before {
      top: 100%;
    }

    svg {
      vertical-align: top;
    }
  }

  &-left {
    .u-ns-bg-before {
      left: 100%;
    }
  }

  &-right {
    .u-ns-bg-before {
      right: 100%;
    }
  }
}

@media (min-width: $g-md) {
  .u-ns-bg-v5 {
    &-top--md,
    &-bottom--md,
    &-left--md,
    &-right--md {
      .u-ns-bg-before {
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        width: auto;
        height: auto;
      }

      svg {
        width: auto;
        height: auto;
      }
    }

    &-top--md,
    &-bottom--md {
      .u-ns-bg-before {
        width: 100%;
      }

      svg {
        width: 100%;
      }
    }

    &-left--md,
    &-right--md {
      .u-ns-bg-before {
        height: 100%;
      }

      svg {
        height: 100%;
        vertical-align: middle;
      }
    }

    &-top--md {
      .u-ns-bg-before {
        bottom: 100%;
      }

      svg {
        vertical-align: bottom;
      }
    }

    &-bottom--md {
      .u-ns-bg-before {
        top: 100%;
      }

      svg {
        vertical-align: top;
      }
    }

    &-left--md {
      .u-ns-bg-before {
        left: 100%;
      }
    }

    &-right--md {
      .u-ns-bg-before {
        right: 100%;
      }
    }
  }
}
