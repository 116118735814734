/*------------------------------------
  Block Alignments
------------------------------------*/
/* Absolute (Position, X, Y) */
.g-absolute-centered {
  @include block-centered(absolute, true, true);

  &--x {
    @include block-centered(absolute, true, false);

    &--sm {
      @media (min-width: $g-sm) {
        @include block-centered(absolute, true, false);
      }
    }

    &--md {
      @media (min-width: $g-md) {
        @include block-centered(absolute, true, false);
      }
    }
  }

  &--y {
    @include block-centered(absolute, false, true);

    &--md {
      @media (min-width: $g-md) {
        @include block-centered(absolute, false, true);
      }
    }

    &--lg {
      @media (min-width: $g-lg) {
        @include block-centered(absolute, false, true);
      }
    }
  }
}
/* O */
.g-transform-origin {
  &--top-left {
    transform-origin: top left;
  }

  &--top-right {
    transform-origin: top right;
  }
}

.g-absolute-centered {
  &--y {
    &--scl-0_6 {
      position: absolute;
      top: 50%;
      transform: scale(0.6) translateY(-50%);
    }
  }
}
/* Relative (Position, X, Y) */
.g-relative-centered {
  @include block-centered(relative, true, true);

  &--x {
    @include block-centered(relative, true, false);
  }

  &--y {
    @include block-centered(relative, false, true);
  }
}
/* Flex centered */
.g-flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;

  &-item {
    flex: 1;

    &--top {
      align-self: flex-start;
    }

    &--bottom {
      align-self: flex-end;
    }
  }
}
/* Flex right */
.g-flex-right--xs {
  justify-content: flex-end;
}
/* Flex middle */
.g-flex-middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  &-item {
    margin-top: auto;
    margin-bottom: auto;

    &--top {
      margin-top: 0;
      margin-bottom: 0;
    }

    &--bottom {
      margin-top: auto;
      margin-bottom: 0;
    }

    &--fixed {
      flex: none;
      max-width: 50%;
    }
  }
}
@media (min-width: $g-md) {
  /* Flex right */
  .g-flex-right--md {
    justify-content: flex-end;
  }

  .g-flex-centered--md {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .g-absolute-centered {
    &--y {
      &--scl-1--md {
        position: absolute;
        top: 50%;
        transform: scale(1) translateY(-50%);
      }
    }
  }
}
@media (min-width: $g-sm) {
  .g-absolute-centered--sm {
    @include block-centered(absolute, true, true);
  }

  .g-absolute-centered--x-sm--reset {
    position: static !important;
    left: auto !important;
    transform: translateX(0) !important;
  }
}
@media (min-width: $g-md) {
  .g-absolute-centered--md {
    @include block-centered(absolute, true, true);
  }
}
@media (min-width: $g-lg) {
  .g-absolute-centered--x--lg {
    @include block-centered(absolute, true, false);
  }

  .g-flex-centered--lg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
