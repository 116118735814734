/*------------------------------------
  Hover Effect of Icon on Block Hover
------------------------------------*/
// Hover Opacity
.u-icon-block--hover:hover .g-opacity-0_5--hover {
  opacity: 0.5;
}

// Hover Color (primary)
.u-icon-block--hover:hover .g-color-primary--hover {
  color: $g-color-primary;
}

// Hover Border Color (primary)
.u-icon-block--hover:hover .g-brd-primary--hover {
  border-color: $g-color-primary;
}

// Hover BG (primary)
.u-icon-block--hover:hover .g-bg-primary--hover {
  background: $g-color-primary;
}

// Hover Color (white)
.u-icon-block--hover:hover .g-color-white--hover {
  color: $g-color-white;
}

// Hover Border Color (white)
.u-icon-block--hover:hover .g-brd-white--hover {
  border-color: $g-color-white;
}

// Hover BG (white)
.u-icon-block--hover:hover .g-bg-white--hover {
  background: $g-color-white;
}

// Hover Color (black)
.u-icon-block--hover:hover .g-color-black--hover {
  color: $g-color-black;
}

// Hover Border Color (black)
.u-icon-block--hover:hover .g-brd-black--hover {
  border-color: $g-color-black;
}

// Hover BG (black)
.u-icon-block--hover:hover .g-bg-black--hover {
  background: $g-color-black;
}

// Hover Color (gray)
.u-icon-block--hover:hover .g-color-gray-dark-v4--hover {
  color: $g-color-gray-dark-v4;
}

// Hover Border Color (gray)
.u-icon-block--hover:hover .g-brd-gray-dark-v4--hover {
  border-color: $g-color-gray-dark-v4;
}

// Hover BG (gray)
.u-icon-block--hover:hover .g-bg-gray-dark-v4--hover {
  background: $g-color-gray-dark-v4;
}

// Hover Color (facebook)
.u-icon-block-hover:hover .g-color-facebook--hover {
  color: $g-color-facebook;
}
// Hover Border Color (facebook)
.u-icon-block-hover:hover .g-brd-facebook--hover {
  border-color: $g-color-facebook;
}
// Hover BG (facebook)
.u-icon-block-hover:hover .g-bg-facebook--hover {
  background: $g-color-facebook;
}

// Hover Color (twitter)
.u-icon-block-hover:hover .g-color-twitter--hover {
  color: $g-color-twitter;
}
// Hover Border Color (twitter)
.u-icon-block-hover:hover .g-brd-twitter--hover {
  border-color: $g-color-twitter;
}
// Hover BG (twitter)
.u-icon-block-hover:hover .g-bg-twitter--hover {
  background: $g-color-twitter;
}

// Hover Color (google-plus)
.u-icon-block-hover:hover .g-color-google-plus--hover {
  color: $g-color-google-plus;
}
// Hover Border Color (google-plus)
.u-icon-block-hover:hover .g-brd-google-plus--hover {
  border-color: $g-color-google-plus;
}
// Hover BG (google-plus)
.u-icon-block-hover:hover .g-bg-google-plus--hover {
  background: $g-color-google-plus;
}

// Hover Color (vk)
.u-icon-block-hover:hover .g-color-vk--hover {
  color: $g-color-vk;
}
// Hover Border Color (vk)
.u-icon-block-hover:hover .g-brd-vk--hover {
  border-color: $g-color-vk;
}
// Hover BG (vk)
.u-icon-block-hover:hover .g-bg-vk--hover {
  background: $g-color-vk;
}

// Hover Color (linkedin)
.u-icon-block-hover:hover .g-color-linkedin--hover {
  color: $g-color-linkedin;
}
// Hover Border Color (linkedin)
.u-icon-block-hover:hover .g-brd-linkedin--hover {
  border-color: $g-color-linkedin;
}
// Hover BG (linkedin)
.u-icon-block-hover:hover .g-bg-linkedin--hover {
  background: $g-color-linkedin;
}

// Hover Color (instagram)
.u-icon-block-hover:hover .g-color-instagram--hover {
  color: $g-color-instagram;
}
// Hover Border Color (instagram)
.u-icon-block-hover:hover .g-brd-instagram--hover {
  border-color: $g-color-instagram;
}
// Hover BG (instagram)
.u-icon-block-hover:hover .g-bg-instagram--hover {
  background: $g-color-instagram;
}

// Hover Color (pinterest)
.u-icon-block-hover:hover .g-color-pinterest--hover {
  color: $g-color-pinterest;
}
// Hover Border Color (pinterest)
.u-icon-block-hover:hover .g-brd-pinterest--hover {
  border-color: $g-color-pinterest;
}
// Hover BG (pinterest)
.u-icon-block-hover:hover .g-bg-pinterest--hover {
  background: $g-color-pinterest;
}
