/*------------------------------------
  Heading-v6-1
------------------------------------*/
.u-heading-v6-2 {
  & .u-heading-v6__title,
  & .u-heading-v6__subtitle {
    @include px-to-rem(padding-left, 90px);
  }
  &.text-center .u-heading-v6__title,
  &.text-right .u-heading-v6__title,
  &.text-center .u-heading-v6__subtitle,
  &.text-right .u-heading-v6__subtitle {
    @include px-to-rem(padding-right, 90px);
  }
  &.text-right .u-heading-v6__title,
  &.text-right .u-heading-v6__subtitle {
    padding-left: 0;
  }
  & .u-heading-v6__title::after {
    @extend %u-heading-v6__title;
    left: 0;
  }
  &.text-center .u-heading-v6__title::before {
    @extend %u-heading-v6__title;
    right: 0;
  }
  &.text-right .u-heading-v6__title::before {
    @extend %u-heading-v6__title;
    right: 0;
  }
  &.text-right .u-heading-v6__title::after {
    display: none;
  }
}
