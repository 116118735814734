/*------------------------------------
  Heading-v2-5
------------------------------------*/
%u-heading-v2-5 {
  @include px-to-rem(width, 70px);
  border-top-width: 2px;
}
.u-heading-v2-5 {
  &--bottom::after {
    @extend %u-heading-v2;
    @extend %u-heading-v2-5;
    @include px-to-rem(margin-top, 20px);
  }
  &--top::before {
    @extend %u-heading-v2;
    @extend %u-heading-v2-5;
    @include px-to-rem(margin-bottom, 20px);
  }
}
