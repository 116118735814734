/*------------------------------------
  Nonstandard Background v6
------------------------------------*/

[class*='u-ns-bg-v6'] {
  position: relative;

  .u-ns-bg-before {
    position: absolute;
    z-index: 2;
  }
}

.u-ns-bg-v6 {
  &-top,
  &-bottom,
  &-left,
  &-right {
    .u-ns-bg-before {
      &::before,
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: {
          style: solid;
          color: transparent;
        }
      }

      &::after {
        position: absolute;
      }
    }
  }

  &-top,
  &-bottom {
    .u-ns-bg-before {
      left: 20px;
    }
  }

  &-left,
  &-right {
    .u-ns-bg-before {
      top: 30px;
    }
  }

  &-top {
    .u-ns-bg-before {
      top: -15px;

      &::before {
        border-width: 0 15px 15px 15px;
      }

      &::after {
        bottom: 0;
        left: 1px;
        border-width: 0 14px 14px 14px;
      }
    }

    &.g-brd-primary {
      .u-ns-bg-before {
        &::before {
          border-bottom-color: $g-color-primary;
        }

        &::after {
          border-bottom-color: $g-color-white;
        }
      }
    }

    &.g-brd-gray-light-v2 {
      .u-ns-bg-before {
        &::before {
          border-bottom-color: $g-color-gray-light-v2;
        }

        &::after {
          border-bottom-color: $g-color-white;
        }
      }
    }

    &.g-brd-black {
      .u-ns-bg-before {
        &::before {
          border-bottom-color: $g-color-black;
        }

        &::after {
          border-bottom-color: $g-color-white;
        }
      }
    }
  }

  &-bottom {
    .u-ns-bg-before {
      bottom: -15px;

      &::before {
        border-width: 15px 15px 0 15px;
      }

      &::after {
        bottom: 1px;
        left: 1px;
        border-width: 14px 14px 0 14px;
      }
    }

    &.g-brd-primary {
      .u-ns-bg-before {
        &::before {
          border-top-color: $g-color-primary;
        }

        &::after {
          border-top-color: $g-color-white;
        }
      }
    }

    &.g-brd-gray-light-v2 {
      .u-ns-bg-before {
        &::before {
          border-top-color: $g-color-gray-light-v2;
        }

        &::after {
          border-top-color: $g-color-white;
        }
      }
    }

    &.g-brd-black {
      .u-ns-bg-before {
        &::before {
          border-top-color: $g-color-black;
        }

        &::after {
          border-top-color: $g-color-white;
        }
      }
    }
  }

  &-left {
    .u-ns-bg-before {
      left: -15px;

      &::before {
        border-width: 15px 15px 15px 0;
      }

      &::after {
        top: 1px;
        right: 0;
        border-width: 14px 14px 14px 0;
      }
    }

    &.g-brd-primary {
      .u-ns-bg-before {
        &::before {
          border-right-color: $g-color-primary;
        }

        &::after {
          border-right-color: $g-color-white;
        }
      }
    }

    &.g-brd-gray-light-v2 {
      .u-ns-bg-before {
        &::before {
          border-right-color: $g-color-gray-light-v2;
        }

        &::after {
          border-right-color: $g-color-white;
        }
      }
    }

    &.g-brd-black {
      .u-ns-bg-before {
        &::before {
          border-right-color: $g-color-black;
        }

        &::after {
          border-right-color: $g-color-white;
        }
      }
    }
  }

  &-right {
    .u-ns-bg-before {
      right: -15px;

      &::before {
        border-width: 15px 0 15px 15px;
      }

      &::after {
        top: 1px;
        left: 0;
        border-width: 14px 0 14px 14px;
      }
    }

    &.g-brd-primary {
      .u-ns-bg-before {
        &::before {
          border-left-color: $g-color-gray-light-v2;
        }

        &::after {
          border-left-color: $g-color-white;
        }
      }
    }

    &.g-brd-gray-light-v2 {
      .u-ns-bg-before {
        &::before {
          border-left-color: $g-color-gray-light-v2;
        }

        &::after {
          border-left-color: $g-color-white;
        }
      }
    }

    &.g-brd-black {
      .u-ns-bg-before {
        &::before {
          border-left-color: $g-color-gray-light-v2;
        }

        &::after {
          border-left-color: $g-color-white;
        }
      }
    }
  }
}

@media (min-width: $g-md) {
  .u-ns-bg-v6 {
    &-top--md,
    &-bottom--md,
    &-left--md,
    &-right--md {
      &.g-brd-primary,
      &.g-brd-gray-light-v2,
      &.g-brd-black {
        .u-ns-bg-before {
          &::before {
            border-top-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
          }

          &::after {
            border-top-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
          }
        }
      }
    }

    &-top--md,
    &-bottom--md {
      .u-ns-bg-before {
        left: 20px;
      }
    }

    &-left--md,
    &-right--md {
      .u-ns-bg-before {
        top: 30px;
      }
    }

    &-top--md {
      .u-ns-bg-before {
        bottom: auto;
        top: -15px;

        &::before {
          border-width: 0 15px 15px 15px;
        }

        &::after {
          top: auto;
          bottom: 0;
          left: 1px;
          border-width: 0 14px 14px 14px;
        }
      }

      &.g-brd-primary {
        .u-ns-bg-before {
          &::before {
            border-bottom-color: $g-color-primary;
          }

          &::after {
            border-bottom-color: $g-color-white;
          }
        }
      }

      &.g-brd-gray-light-v2 {
        .u-ns-bg-before {
          &::before {
            border-bottom-color: $g-color-gray-light-v2;
          }

          &::after {
            border-bottom-color: $g-color-white;
          }
        }
      }

      &.g-brd-black {
        .u-ns-bg-before {
          &::before {
            border-bottom-color: $g-color-black;
          }

          &::after {
            border-bottom-color: $g-color-white;
          }
        }
      }
    }

    &-bottom--md {
      .u-ns-bg-before {
        top: auto;
        bottom: -15px;

        &::before {
          border-width: 15px 15px 0 15px;
        }

        &::after {
          top: auto;
          bottom: 1px;
          left: 1px;
          border-width: 14px 14px 0 14px;
        }
      }

      &.g-brd-primary {
        .u-ns-bg-before {
          &::before {
            border-top-color: $g-color-primary;
          }

          &::after {
            border-top-color: $g-color-white;
          }
        }
      }

      &.g-brd-gray-light-v2 {
        .u-ns-bg-before {
          &::before {
            border-top-color: $g-color-gray-light-v2;
          }

          &::after {
            border-top-color: $g-color-white;
          }
        }
      }

      &.g-brd-black {
        .u-ns-bg-before {
          &::before {
            border-top-color: $g-color-black;
          }

          &::after {
            border-top-color: $g-color-white;
          }
        }
      }
    }

    &-left--md {
      .u-ns-bg-before {
        left: -15px;
        right: auto;

        &::before {
          border-width: 15px 15px 15px 0;
        }

        &::after {
          top: 1px;
          bottom: auto;
          right: 0;
          border-width: 14px 14px 14px 0;
        }
      }

      &.g-brd-primary {
        .u-ns-bg-before {
          &::before {
            border-right-color: $g-color-primary;
          }

          &::after {
            border-right-color: $g-color-white;
          }
        }
      }

      &.g-brd-gray-light-v2 {
        .u-ns-bg-before {
          &::before {
            border-right-color: $g-color-gray-light-v2;
          }

          &::after {
            border-right-color: $g-color-white;
          }
        }
      }

      &.g-brd-black {
        .u-ns-bg-before {
          &::before {
            border-right-color: $g-color-black;
          }

          &::after {
            border-right-color: $g-color-white;
          }
        }
      }
    }

    &-right--md {
      .u-ns-bg-before {
        left: auto;
        right: -15px;

        &::before {
          border-width: 15px 0 15px 15px;
        }

        &::after {
          top: 1px;
          bottom: auto;
          left: 0;
          border-width: 14px 0 14px 14px;
        }
      }

      &.g-brd-primary {
        .u-ns-bg-before {
          &::before {
            border-left-color: $g-color-primary;
          }

          &::after {
            border-left-color: $g-color-white;
          }
        }
      }

      &.g-brd-gray-light-v2 {
        .u-ns-bg-before {
          &::before {
            border-left-color: $g-color-gray-light-v2;
          }

          &::after {
            border-left-color: $g-color-white;
          }
        }
      }

      &.g-brd-black {
        .u-ns-bg-before {
          &::before {
            border-left-color: $g-color-black;
          }

          &::after {
            border-left-color: $g-color-white;
          }
        }
      }
    }
  }
}
