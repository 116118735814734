@import "styles/variables.scss";
.startPageInfo {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  z-index: 2;
  h1 {
    color: $primary;
    font-weight: bolder;
    font-size: 4rem;
  }
  span {
    font-size: 2.5rem;
  }
}

.blockSearch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.searchInput {
  position: absolute;
  bottom: 40px;
  width: 100%;
  padding-left: 60px;
}
.chooseBlock {
  position: absolute;
  background-color: $white;
  text-align: left;
  border: 1px solid $light-grayish-blue;
  width: calc(100% - 194px);
  margin-left: 60px;
  top: 77%;
  & > div {
    padding: 20px;
  }
  p {
    margin: 0;
    padding: 5px;
    &:hover {
      color: $primary;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
.navbg {
  display: flex;
  background: $white;
  border: 1px solid $light-grayish-blue;
  min-height: 177.5px;
  align-items: center;
  .searchBlock {
    width: 100%;
    padding-right: 135px;
    & > div:nth-of-type(1) {
      position: relative;
      min-height: 177.5px;
    }
  }
}

.tab {
  background: #f6f3f3;
  border: 1px solid $light-grayish-blue;
  width: 135px;
  & > p {
    margin: 0;
    cursor: pointer;
    color: $gray;
  }
}
.tabcontent {
  color: #333 !important;
}
.toastBody {
  display: flex;
  justify-content: center;
}
.btn {
  width: 135px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  border: none;
  font-size: 1rem;
  color: $white;
  padding: 0;
}
.link {
  margin-bottom: -1px;
  background: none;
}
.active {
  background-color: $white;
  border-top: 2px solid $primary !important;
  color: $primary;
}
.activeText {
  padding-top: 6px !important;
  color: $primary !important;
  font-weight: bolder;
}
.activeLink {
  text-decoration: none !important;
  margin: 0;
  border-bottom: 1px solid $light-gray;
  border-left: 1px solid $light-gray;
  border-right: 1px solid $light-gray;
  padding: 10px;
}
.activeLink:first-child {
  border-top: 1px solid $light-gray;
}
.activeLink:hover {
  font-weight: bold;
  background-color: $light-grayish-blue;
}
.myStyle {
  color: $primary;
  word-wrap: break-word;
}
.heading {
  line-height: 0.74;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 3rem;
  text-align: left;
  color: $primary;
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 50%;
    height: 1px;
    background: #000;
    top: -2.562rem;
  }
}
.paragraph {
  margin-bottom: 0;
  text-align: end;
  font-size: 1rem !important;
}

.buttonLink {
  cursor: pointer;
  font-size: 1.25rem;
  background-color: $primary;
  color: $white;
  padding: 0.5rem 0.7rem;
}
.startPageMietspiegelImage {
  height: 400px;
  -o-object-fit: cover;
  object-fit: cover;
}
.startPageImmobilienImage {
  height: 400px;
  -o-object-fit: cover;
  object-fit: cover;
}
.immobilienOffersBox {
  article {
    border: 2px solid $primary;
    div > p:hover {
      background-color: $primary;
      color: $white !important;
      a {
        color: $white !important;
        text-decoration: none;
      }
    }
  }
}
.immobilienOffersBox:hover {
  .immobilienOffersIcon {
    background-color: $primary;
    color: $white !important;
    border: none;
    h3 {
      color: $white !important;
    }
  }
}
.immobilienOffersIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 255px;
  border-left: 2px solid $light-grayish-blue;
  border-right: 2px solid $light-grayish-blue;
  border-top: 2px solid $light-grayish-blue;
}
.apartament {
  width: 35px !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
  color: $light-gray;
}
.housingSearchBox {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 70px;
  height: 100%;
  color: $white;
}
.span {
  &:after {
    content: '';
    height: 50%;
    width: 1px;
    position: absolute;
    right: -23px;
    top: 25%;
    background-color: $white;
  }
}
.house {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 34px;
  height: 100%;
  color: $gray;
}
.rentOrBuy {
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 60px;
  color: $gray;
  & > span {
    height: 24px;
    line-height: 2.8;
  }
}
.rentOrBuyFromSearchBox {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 70px;
  height: 100%;
  color: $white;
}
.ask {
  display: flex;
  width: 100%;
  align-items: flex-end;
  padding-top: 4.2857142857rem;
  padding-left: 4.2857142857rem;
  color: grey;
  font-size: 14px;
  height: 84px;
  & p {
    line-height: 0.9;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 990px) {
  .startPageInfo {
    h1 {
      font-size: 3.5rem;
    }
    span {
      font-size: 2rem;
    }
  }
}
@media screen and (max-width: 770px) {
  .startPageInfo {
    h1 {
      font-size: 2.5rem;
    }
    span {
      font-size: 2rem;
    }
  }
  .searchBlock {
    position: absolute;
    bottom: 0;
    padding-right: 30px !important;
  }
  .searchInput {
    padding-right: 10px;
  }
  .imagesStartPage {
    padding-left: 0;
  }
}
.cityBox {
  display: flex;
  flex-wrap: wrap;
  & > div:last-child {
    padding-right: 0;
  }
  & > div:first-child {
    padding-left: 0;
  }
}
@media screen and (max-width: 770px) {
  .section {
    padding-right: 0;
  }
  .immobnilienBox {
    & > div {
      margin-top: 10px;
      padding-left: 0;
    }
  }
  .startPageMietspiegelImage {
    padding-left: 0;
  }
  .startPageImmobilienImage {
    padding-right: 0;
  }
  .cityBox {
    & > div {
      padding: 0;
    }
  }
}
@media screen and (max-width: 540px) {
  .startPageInfo {
    h1 {
      font-size: 2rem;
    }
    span {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 420px) {
  .startPageInfo {
    width: 100%;
  }
  .btn {
    height: 36.5px;
  }
  .searchBlock {
    height: auto !important;
    padding-bottom: 6px;
  }
  .searchInput {
    padding-bottom: 10px;
    padding-left: 10px;
    & > div:nth-of-type(1) {
      flex-direction: column;
      padding: 0 !important;
      input {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
  }
  .switcherBlock {
    padding-left: 10px !important;
  }
  .switcherBlockHomePage {
    padding-left: 10px !important;
  }
}
