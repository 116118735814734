/*------------------------------------
  Info Block v2-1
------------------------------------*/

// Variables
$u-info-v2-1__item-height: 65px;
$u-info-v2-1__item-difference: 15px;

.u-info-v2-1__item {
  height: $u-info-v2-1__item-height;
  transition: 0.3s ease-out;

  &:hover {
    height: $u-info-v2-1__item-height + $u-info-v2-1__item-difference;
    margin-top: -($u-info-v2-1__item-difference);
  }
}
