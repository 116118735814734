/*------------------------------------
  Gradient Colors
------------------------------------*/
/* Cyan Gradient */
.g-color-cyan-gradient-opacity-v1 {
  @include gradient-directional(
    $start-color: rgba(#009, 0.55),
    $end-color: rgba(#00bed6, 0.6),
    $deg: 160deg
  );
  @include gradient-text;
}

/* Blue Gradient */
.g-color-blue-gradient-opacity-v1 {
  @include gradient-directional(
    $start-color: rgba(#8654da, 1),
    $end-color: rgba(#42e5f8, 0.8),
    $deg: -260deg
  );
  @include gradient-text;
}
