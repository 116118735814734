/*------------------------------------
  Pin Map
------------------------------------*/

.point {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: rgba($g-color-black, 0.5);

  &::before,
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
  }

  &::before {
    top: 5px;
    left: 5px;
    width: 11px;
    height: 11px;
    background: $g-color-primary;
  }

  &::after {
    top: 8px;
    left: 8px;
    width: 5px;
    height: 5px;
    background: rgba($g-color-black, 0.5);
  }
}

.mwp-wrap {
  box-shadow: 0 1px 9px -1px;
  left: -82px;
  bottom: 35px !important;

  &::after {
    content: '';
    position: absolute;
    top: 99%;
    left: 50%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border: {
      width: 14px 14px 0 14px;
      style: solid;
      color: $g-color-gray-dark-v1 transparent transparent transparent;
    }
  }
}
