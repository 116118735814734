/*------------------------------------
	Dropdowns
------------------------------------*/
[class*='u-dropdown--'] [class*='u-dropdown--'] {
  left: 100%;
  top: 0;

  &.u-dropdown--reverse-x {
    left: auto;
    right: 100%;
  }
}

.u-dropdown--simple,
.u-dropdown--jquery-slide,
.u-dropdown--css-animation {
  &.u-dropdown--reverse-y {
    top: auto;
    bottom: 100%;

    margin-top: 0 !important;
    @include px-to-rem(margin-bottom, 20px);
  }
}

/* Simple Dropdown */
.u-dropdown--simple.u-dropdown--hidden {
  display: none;
}

/* CSS Animation Dropdown */
.u-dropdown--css-animation {
  animation-duration: 1s;

  &.u-dropdown--hidden {
    opacity: 0;
    visibility: hidden;
  }
}

/* jQuery Slide */
.u-dropdown--jquery-slide {
  &.u-dropdown--hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.u-dropdown--hidden:not(.hs-sub-menu-opened) * {
  transition: none !important;

  &::before,
  &::after {
    transition: none !important;
  }
}
