/*------------------------------------
  Effect v4
------------------------------------*/
@import 'icon-hover-extend';

.u-icon-effect-v4--hover {
  transition: transform ease-out 0.1s, background 0.2s;
  overflow: inherit;
}

.u-icon-effect-v4--hover::after {
  @extend %u-icon-hover-effect-after;
  top: 0;
  left: 0;
  padding: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  opacity: 0;
  z-index: -1;
  transform: scale(0.9);
}

.u-icon-effect-v4--hover:hover,
.u-icon-block--hover:hover .u-icon-effect-v4--hover {
  transform: scale(1.1);
}

.u-icon-rotation.u-icon-effect-v4--hover:hover,
.u-icon-block--hover:hover .u-icon-rotation.u-icon-effect-v4--hover {
  transform: scale(1.1) rotate(45deg);
}

.u-icon-effect-v4--hover:hover::after {
  animation: sonarEffect-default 1.3s ease-out 75ms;
}

.u-icon-effect-v4--hover.g-bg-primary:hover::after,
.u-icon-v2.u-icon-effect-v4--hover.g-color-primary:hover::after,
.u-icon-block--hover:hover .u-icon-effect-v4--hover.g-bg-primary::after,
.u-icon-block--hover:hover
  .u-icon-v2.u-icon-effect-v4--hover.g-color-primary::after {
  animation: sonarEffect-primary 1.3s ease-out 75ms;
}

@keyframes sonarEffect-default {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px $g-color-gray-light-v3, 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px $g-color-gray-light-v3, 0 0 0 10px rgba(255, 255, 255, 0.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes sonarEffect-primary {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px $g-color-primary, 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px $g-color-primary, 0 0 0 10px rgba(255, 255, 255, 0.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
