/*------------------------------------
  Z-Index
------------------------------------*/
.g-z-index {
  &-auto {
    z-index: auto;
  }
  &-1 {
    z-index: 1;
  }
  &-2 {
    z-index: 2;
  }
  &-3 {
    z-index: 3;

    &--hover:hover {
      z-index: 3;
    }
  }
  &-4 {
    z-index: 4;

    &--hover:hover {
      z-index: 4;
    }
  }
  &-99 {
    z-index: 99;
  }
  &-9999 {
    z-index: 9999;
  }
  &-minus {
    &-1 {
      z-index: -1;
    }
  }
}
