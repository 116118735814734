/*------------------------------------
  Go To v4
------------------------------------*/

.u-go-to-v4 {
  position: relative;
  width: 22px;
  height: 35px;
  border: 2px solid $g-color-primary;
  border-radius: 15px;

  &::before {
    width: 7px;
    height: 7px;
    background-color: $g-color-white;
    border-radius: 50%;
    content: ' ';
    @include block-centered(absolute, true, false);

    animation-duration: 2s;
    animation-name: u-go-to-v4;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

@include keyframes(u-go-to-v4) {
  0% {
    top: 20%;
    bottom: 0;
  }
  50% {
    top: 50%;
    bottom: 0;
  }
  100% {
    top: 20%;
    bottom: 100%;
  }
}
