/*------------------------------------
  Icon-v3
------------------------------------*/
@import 'icons-sizes-vn';

.u-icon-v3 {
  @extend %u-icon-size;
  background-color: $g-color-gray-light-v4;

  //
  // Sizes
  //

  // Default (md)
  & .u-icon__elem-regular,
  & .u-icon__elem-hover {
    @extend %u-icon-size;
  }

  &.u-icon-size--xs,
  &.u-icon-size--xs .u-icon__elem-regular,
  &.u-icon-size--xs .u-icon__elem-hover {
    @extend %u-icon-size--xs;
  }

  &.u-icon-size--sm,
  &.u-icon-size--sm .u-icon__elem-regular,
  &.u-icon-size--sm .u-icon__elem-hover {
    @extend %u-icon-size--sm;
  }

  &.u-icon-size--lg,
  &.u-icon-size--lg .u-icon__elem-regular,
  &.u-icon-size--lg .u-icon__elem-hover {
    @extend %u-icon-size--lg;
  }

  &.u-icon-size--xl,
  &.u-icon-size--xl .u-icon__elem-regular,
  &.u-icon-size--xl .u-icon__elem-hover {
    @extend %u-icon-size--xl;
  }

  &.u-icon-size--2xl,
  &.u-icon-size--2xl .u-icon__elem-regular,
  &.u-icon-size--2xl .u-icon__elem-hover {
    @extend %u-icon-size--2xl;
  }

  &.u-icon-size--3xl,
  &.u-icon-size--3xl .u-icon__elem-regular,
  &.u-icon-size--3xl .u-icon__elem-hover {
    @extend %u-icon-size--3xl;
  }
}
