/////////////////////////////////////////////////////////
// OUTER CONTAINER

@media screen and (max-width: 550px) {
  #__next > div > div.Layout_content__Yue8p > div.container {
    padding-left: 0;
    padding-right: 0;
  }
}

.cards {
  line-height: 1.2;

  &__container {
    width: 587px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;

    @media (hover: hover) {
      &:hover {
        background-color: darken(#f7f7f7, $amount: 1%);
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.35);
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
    @media screen and (max-width: 1000px) {
      margin: 0 auto 24px;
    }
    @media screen and (max-width: 770px) {
      width: 520px;
    }

    @media screen and (max-width: 550px) {
      height: auto;
      width: 100%;
    }

    &--inner {
      display: flex;
      min-height: 303px;

      @media screen and (max-width: 550px) {
        flex-direction: column;
        padding: 16px;
      }
    }
  }

  /////////////////////////////////////////////////////////
  // PICTURE

  &__img {
    width: 250px;
    height: 303px;
    max-height: 303px;

    @media screen and (max-width: 770px) {
      width: 180px;
    }
    @media screen and (max-width: 550px) {
      margin: 0 auto;
      width: 100%;
      height: 240px;
      max-height: 240px;
    }
  }

  /////////////////////////////////////////////////////////
  // RIGHT SIDE

  &__details {
    width: 337px;
    margin: 24px;
    margin-bottom: 0;

    @media screen and (max-width: 770px) {
      width: 287px;
      margin: 18px;
    }

    @media screen and (max-width: 550px) {
      width: 100%;
      margin: 24px auto 0;
    }
  }
  /////////////////////////////////////////////////////////////
  &__rent-size-room {
    display: flex;
    margin-bottom: 16px;

    & > p:nth-child(1) {
      width: 45.6666666667%;
    }
    & > p:nth-child(2) {
      width: 37.3333333333%;
      padding-right: 15px;

      @media screen and (max-width: 770px) {
        padding: 0;
      }
    }
    & > p:nth-child(3) {
      width: 18%;
      padding-left: 15px;

      @media screen and (max-width: 770px) {
        padding: 0;
      }
    }

    p {
      font-size: 20px;
      line-height: 1;
      margin: 0;

      strong {
        font-weight: 700;
        font-family: 'Mulish', Helvetica, Arial, sans-serif;
        font-size: inherit;
      }

      span {
        font-size: 12px;
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
        color: #434343;
      }
    }
  }
  /////////////////////////////////////////////////////////////

  &__header {
    margin-bottom: 14px;

    & > a {
      color: #434343;
      cursor: pointer;
      display: inline-block;

      &:hover {
        color: #990033;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &--title {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__iib-lagescore {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    color: #434343;
  }

  /////////////////////////////////////////////////////////
  // RATING CONTAINER FOR LABEL & IMG & WRAPPER

  &__rating {
    display: flex;
    flex-wrap: nowrap;
    min-height: 20px;
    position: relative;
    margin-bottom: 4px;

    @media screen and (max-width: 360px) {
      flex-wrap: wrap;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
    .rating__icon-container-label {
      font-size: 13px;
      height: 13px;
      width: 71px;
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      font-weight: 500;
      margin: auto 0;
      position: relative;
      color: #434343;
      text-align: start;
    }
  }
}
.label-image__container {
  display: flex;
  height: 20px;
  width: 15px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;

  @media screen and (max-width: 360px) {
    margin-left: 5px;
  }
}

.einfach-top {
  display: flex;
  justify-content: space-between;
  width: 196px;
  height: 13px;
  font-size: 12px;
  margin: 7px 0 0 auto;
  padding-bottom: 16px;
  color: #434343;

  @media screen and (max-width: 770px) {
    margin-left: 112px;
    width: 175px;
  }
  @media screen and (max-width: 360px) {
    margin-left: 10px;
    width: 187px;
  }
}

/////////////////////////////////////////////////////////
// BARS
.scorebars {
  width: 196px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 4px;
  grid-template-areas: '. . . . .';
  margin: auto;

  @media screen and (max-width: 770px) {
    margin: auto auto auto 10px;
    width: 187px;
  }

  &__container {
    height: 8px;
    border: 1px solid #820027;
    position: relative;

    &-null {
      border: 1px solid gray;
    }
  }

  &__bars {
    position: absolute;
    top: -1px;
    width: 19px;
    height: 8px;
    background-color: #820027;

    &--left {
      left: -1px;
    }

    &--right {
      right: -1px;
    }
  }
}

/////////////////////////////////////////////////////////
// DESKTOP TOOLTIP - ICON POSITIONING

.tooltip-image {
  position: absolute;
  left: -20px;

  @media screen and (max-width: 550px) {
    left: -20px;
  }
}

/////////////////////////////////////////////////////////
// LINK TO DETAILSPAGE BUTTON

.expose-link,
.expose-link:focus,
.expose-link:active,
.expose-link:visited {
  font-size: 15px;
  background-color: #e3254c;
  padding: 8px;
  color: white;
  text-decoration: none;
  margin: 8px 0 16px -12px;
  align-self: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  display: inline-block;
}
/////////////////////////////////////////////////////////
// VIVAWEST -> MOVE TO DIFFERENT CSS
.viva-link,
.viva-link:focus,
.viva-link:active,
.viva-link:visited {
  font-size: 15px;
  background-color: #fff;
  color: #4ba1bd;
  padding: 4px;
  text-align: center;
  margin-top: 10px;
}

///////////////////////////////////////////////////////////
// WOHNPREIS TABLET/MOBILE ACCORDION

.accordion {
  & > button {
    background-color: transparent !important;
    border: none;
  }
  & > div {
    position: relative;
    font-size: 13px;
    width: 283px;
    border: 1px solid #820027;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    @media screen and (max-width: 341px) {
      width: 217px;
    }

    & > div::before {
      content: '';
      position: absolute;
      -webkit-clip-path: polygon(0 7px, 7px 0, 15px 7px, 0px 7px);
      clip-path: polygon(0 7px, 7px 0, 15px 7px, 0px 7px);
      background-color: #820027;
      top: -25px;
      left: -7px;
      height: 15px;
      width: 15px;
      @media screen and (max-width: 341px) {
        content: none;
      }
    }
    & > div {
      margin: 16px 16px 0 32px;
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }
}

.mobile-tooltip {
  position: relative;
  margin: 16px 8px 0 30px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
}
/////////////////////////////////////////////////////////
// MOBILE VIVAWEST ACCORDION -> MOVE TO DIFFERENT CSS FILE
#viva-acc {
  background-color: #4ba1bd;
  color: #fff;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  width: 100% !important;
  max-width: 303px !important;
  border: none !important;
  padding: 11px;

  @media screen and (max-width: 319px) {
    width: 217px !important;
  }
}
#viva-acc > div {
  margin: 0 !important;
}
#viva-acc > div::before {
  content: none;
}

/////////////////////////////////////////////////////////
// DESKTOP WOHNPREIS TOOLTIP
#tooltip-right {
  font-size: 30px;
}
#tooltip-right {
  opacity: 1;

  & > * {
    left: -5px;
  }
}

#tooltip-right .tooltip-inner {
  position: relative;
  padding: 16px 8px 10px 30px !important;
  height: auto !important;
  min-width: 234px !important;
  background-color: #fff !important;
  color: #434343 !important;
  border: #820027 1px solid;
  font-size: 13px;
  line-height: 1;
  text-align: start;
}

/////////////////////////////////////////////////////////
// DESKTOP VIVAWEST TOOLTIP -> MOVE TO DIFFERENT CSS FILE
#viva-tooltip {
  opacity: 1;
}
#viva-tooltip .tooltip-inner {
  background-color: #4ba1bd !important;
  font-size: 14px;
  padding: 11px;
  line-height: 1.3;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  color: #fff;
}
#viva-tooltip .arrow::before {
  border-top-color: #4ba1bd;
  border-width: 0.7rem 0.7rem 0;
  left: -5px;
}

/////////////////////////////////////////////////////////
// CONTAINER IF NO WOHNLAGESCORE AVAILABLE
.noscore {
  font-size: 13px;
  border: #820027 1px solid;
  line-height: 1;
  color: #434343 !important;
  background-color: #fff !important;
  padding: 10px !important;
  border-radius: 5px !important;
}

///////////////////////////////////////
// HOUSEDETAILS ARROWS!

.carousel-control-next {
  opacity: 1 !important;
}
.carousel-control-prev {
  opacity: 1 !important;
}

.carousel-control-next-icon {
  position: relative;
  background-image: none !important;
}

.carousel-control-prev-icon {
  position: relative;
  background-image: none !important;
}

.carousel-control-prev-icon::before {
  content: '';
  z-index: -10;
  position: absolute;
  height: 32px;
  width: 32px;
  background: white;
  opacity: 0.8;
  right: 5px;
  top: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.carousel-control-next-icon::before {
  content: '';
  z-index: -10;
  position: absolute;
  height: 32px;
  width: 32px;
  background: white;
  opacity: 0.8;
  left: 5px;
  top: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.carousel-control-prev-icon::after {
  content: '';
  z-index: -10;
  position: absolute;
  top: 8px;
  right: 11px;
  height: 16px;
  width: 16px;
  border: solid #555;
  border-width: 0 4px 4px 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
}

.carousel-control-next-icon::after {
  content: '';
  z-index: -10;
  position: absolute;
  top: 8px;
  left: 11px;
  height: 16px;
  width: 16px;
  border: solid #555;
  border-width: 0 4px 4px 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

///////////////////////////////////
// Mui-accordion stying for mobile

.MuiPaper-root {
  box-shadow: none !important;
  background-color: transparent !important;
  margin: 0 !important;
  max-width: 330px !important;

  &::before {
    content: none !important;
  }
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}
.MuiButtonBase-root {
  min-height: 10px !important;
  padding: 0 !important;
}

.MuiAccordionDetails-root {
  position: relative !important;
  border-radius: 14px;
  border: 1px solid #820027 !important;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  padding: 0 !important;
}
