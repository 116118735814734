/*------------------------------------
  Icon-v5
------------------------------------*/
@import 'icons-sizes-vn';

.u-icon-v5 {
  @extend %u-icon-size;
  position: relative;

  body & {
    background: transparent !important; // increasing the specificity
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.45em 0 0 -0.4em;
    width: 0.8em;
    height: 0.8em;
    background-color: $g-color-gray-light-v4;
    border-radius: 50%;
    opacity: 0.7;
  }

  & i {
    position: relative;
    z-index: 2;
  }

  //
  // Sizes
  //

  &.u-icon-size--xs {
    @extend %u-icon-size--xs;
  }
  &.u-icon-size--sm {
    @extend %u-icon-size--sm;
  }
  &.u-icon-size--lg {
    @extend %u-icon-size--lg;
  }
  &.u-icon-size--xl {
    @extend %u-icon-size--xl;
  }
  &.u-icon-size--2xl {
    @extend %u-icon-size--2xl;
  }
  &.u-icon-size--3xl {
    @extend %u-icon-size--3xl;
  }

  //
  // Colors
  //

  &.g-bg-main::after {
    background-color: $g-color-main;
  }
  &.g-bg-primary::after {
    background-color: $g-color-primary;
  }
  &.g-bg-black::after {
    background-color: $g-color-black;
  }
  &.g-bg-white::after {
    background-color: $g-color-white;
  }
  &.g-bg-gray {
    &-light {
      &-v1::after {
        background-color: $g-color-gray-light-v1;
      }
      &-v2::after {
        background-color: $g-color-gray-light-v2;
      }
      &-v3::after {
        background-color: $g-color-gray-light-v3;
      }
      &-v4::after {
        background-color: $g-color-gray-light-v4;
      }
      &-v5::after {
        background-color: $g-color-gray-light-v5;
      }
    }
    &-dark {
      &-v1::after {
        background-color: $g-color-gray-dark-v1;
      }
      &-v2::after {
        background-color: $g-color-gray-dark-v2;
      }
      &-v3::after {
        background-color: $g-color-gray-dark-v3;
      }
      &-v4::after {
        background-color: $g-color-gray-dark-v4;
      }
      &-v5::after {
        background-color: $g-color-gray-dark-v5;
      }
    }
  }
  &.g-bg-green::after {
    background-color: $g-color-green;
  }
  &.g-bg-blue::after {
    background-color: $g-color-blue;
  }
  &.g-bg-lightblue::after {
    background-color: $g-color-lightblue;
  }
  &.g-bg-lightblue-v1::after {
    background-color: $g-color-lightblue-v1;
  }
  &.g-bg-darkblue::after {
    background-color: $g-color-darkblue;
  }
  &.g-bg-indigo::after {
    background-color: $g-color-indigo;
  }
  &.g-bg-red::after {
    background-color: $g-color-red;
  }
  &.g-bg-lightred::after {
    background-color: $g-color-lightred;
  }
  &.g-bg-darkred::after {
    background-color: $g-color-darkred;
  }
  &.g-bg-purple::after {
    background-color: $g-color-purple;
  }
  &.g-bg-darkpurple::after {
    background-color: $g-color-darkpurple;
  }
  &.g-bg-pink::after {
    background-color: $g-color-pink;
  }
  &.g-bg-orange::after {
    background-color: $g-color-orange;
  }
  &.g-bg-deeporange::after {
    background-color: $g-color-deeporange;
  }
  &.g-bg-yellow::after {
    background-color: $g-color-yellow;
  }
  &.g-bg-aqua::after {
    background-color: $g-color-aqua;
  }
  &.g-bg-cyan::after {
    background-color: $g-color-cyan;
  }
  &.g-bg-teal::after {
    background-color: $g-color-teal;
  }
  &.g-bg-brown::after {
    background-color: $g-color-brown;
  }
  &.g-bg-bluegray::after {
    background-color: $g-color-bluegray;
  }
}
