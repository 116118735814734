/*------------------------------------
  Border Gradient Colors
------------------------------------*/
/* Cyan Gradient */
.g-brd-cyan-gradient-opacity-v1 {
  @include gradient-brd(
    $start-color: rgba(#009, 0.55),
    $end-color: rgba(#00bed6, 0.6),
    $deg: 160deg
  );
}
