/*------------------------------------
  Navigation Style v6
------------------------------------*/

.navbar .u-main-nav-v6 {
  .nav-link {
    @extend %u-main-nav-base-link;

    color: $g-color-gray-dark-v2;
    height: 100%;
    @include px-to-rem(padding, 40px 38px);
  }

  .nav-item {
    &.hs-has-sub-menu,
    &.hs-has-mega-menu,
    &.dropdown {
      @extend %u-main-nav-has-sub-menu;

      > a {
        @include px-to-rem(padding-right, 38px);

        &::after {
          position: static;

          display: inline-block;
          vertical-align: middle;
          @include px-to-rem(margin-left, 10px);

          transform: none;
        }
      }
    }

    &.active > .nav-link,
    &:hover > .nav-link,
    &:focus > .nav-link,
    &.show > .nav-link,
    &.hs-sub-menu-opened > .nav-link,
    .nav-link:focus {
      color: $g-color-white;
      background-color: $g-color-primary;
    }
  }
}

@media all and (max-width: #{$g-xl - 1}) {
  .navbar .u-main-nav-v6 {
    .nav-link {
      @include px-to-rem(padding, 30px 20px);
    }

    .nav-item {
      &.hs-has-sub-menu,
      &.hs-has-mega-menu,
      &.dropdown {
        > a {
          @include px-to-rem(padding-right, 20px);
        }
      }
    }
  }
}
