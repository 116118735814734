/*------------------------------------
  Effect v1
------------------------------------*/
@import 'icon-hover-extend';

.u-icon-effect-v1-1--hover,
.u-icon-effect-v1-2--hover {
  position: relative;
  overflow: inherit;
}

.u-icon-effect-v1-1--hover::after,
.u-icon-effect-v1-2--hover::after {
  @extend %u-icon-hover-effect-after;
  @include px-to-rem(top, -7px);
  @include px-to-rem(left, -7px);
  @include px-to-rem(padding, 7px);
  box-shadow: 0 0 0 1px $g-color-primary;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.u-icon-effect-v1-1--hover:hover,
.u-icon-effect-v1-2--hover:hover,
.u-icon-v4.u-icon-effect-v1-1--hover:hover .u-icon-v4-inner,
.u-icon-v4.u-icon-effect-v1-2--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-effect-v1-1--hover,
.u-icon-block--hover:hover .u-icon-effect-v1-2--hover,
.u-icon-block--hover:hover
  .u-icon-v4.u-icon-effect-v1-1--hover
  .u-icon-v4-inner,
.u-icon-block--hover:hover
  .u-icon-v4.u-icon-effect-v1-2--hover
  .u-icon-v4-inner {
  color: $g-color-white;
  border-color: $g-color-primary;
  background: $g-color-primary !important;
}

.u-icon-v4.u-icon-effect-v1-1--hover:hover,
.u-icon-v4.u-icon-effect-v1-2--hover:hover,
.u-icon-block--hover:hover .u-icon-v4.u-icon-effect-v1-1--hover,
.u-icon-block--hover:hover .u-icon-v4.u-icon-effect-v1-2--hover {
  border-color: transparent;
}

// Effect v1-1
.u-icon-effect-v1-1--hover::after {
  transform: scale(0.8);
}

.u-icon-effect-v1-1--hover:hover::after,
.u-icon-block--hover:hover .u-icon-effect-v1-1--hover::after {
  transform: scale(1);
  opacity: 1;
}

// Effect v1-2
.u-icon-effect-v1-2--hover::after {
  transform: scale(1.2);
}

.u-icon-effect-v1-2--hover:hover::after,
.u-icon-block--hover:hover .u-icon-effect-v1-2--hover::after {
  transform: scale(1);
  opacity: 1;
}

.u-icon-v4.u-icon-effect-v1-2--hover:hover::after,
.u-icon-block--hover:hover .u-icon-v4.u-icon-effect-v1-2--hover::after {
  transform: scale(0.8);
}
