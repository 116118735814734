/*------------------------------------
  Dropcaps
------------------------------------*/
%u-dropcap {
  float: left;
  font-size: 3.57rem;
  line-height: 1;
}
.u-dropcap {
  @extend %u-dropcap;

  &-underline {
    @extend %u-dropcap;
    @include px-to-rem(padding-bottom, 5px);
    border-bottom: 2px solid;
  }
  &-bg {
    @extend %u-dropcap;
    @include px-to-rem(width, 70px);
    @include px-to-rem(height, 70px);
    @include px-to-rem(margin-top, 5px);
    text-align: center;
    line-height: 4.78rem;
    // background-color: $g-color-main;
  }
  &-bordered {
    @extend %u-dropcap;
    @include px-to-rem(width, 70px);
    @include px-to-rem(height, 70px);
    @include px-to-rem(margin-top, 5px);
    text-align: center;
    line-height: 4.35rem;
    border: 3px solid;
  }
}
