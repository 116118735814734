//
// Buttons
//

/* Button Styles
------------------------------------*/
/* General Button Styles */
.btn {
  position: relative;
  transition: 0.2s ease;
  cursor: pointer;

  &:focus,
  &:active:focus,
  &.active:focus {
    outline: 0 none;
    box-shadow: none;
  }
}

/* Button Content
------------------------------------*/
.u-btn-content {
  white-space: normal;
}

/* Buttons Only Icon (O)
------------------------------------*/
.u-btn-only-icon {
  position: relative;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

/* Button Sizes
------------------------------------*/
//
// e.g. @include g-button-size ($padding-y, $padding-x, $font-size);
//

/* Extra Small */
.btn-xs {
  @include u-button-size(2px, 7px, 11px);
}

/* Medium */
.btn-md {
  @include u-button-size(8px, 20px, 15px);
}

/* Extra Large */
.btn-xl {
  @include u-button-size(13px, 26px, 18px);
}

/* Extramly Large */
.btn-xxl {
  @include u-button-size(15px, 30px, 21px);
}

/* Button Types
------------------------------------*/
// Inset
@import 'inset/button-inset';

// Button 3d
@import '3d/button-3d';

// Button 3d
@import 'skew/button-skew';

/* Button Hovers
------------------------------------*/
[class*='u-btn-hover'] {
  z-index: 1;

  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }

  &::after {
    position: absolute;
    content: '';
    z-index: -1;
    transition: all 0.3s;
  }
}

// Hover v1-1
@import 'hovers/button-hover-v1-1';

// Hover v1-2
@import 'hovers/button-hover-v1-2';

// Hover v1-3
@import 'hovers/button-hover-v1-3';

// Hover v1-4
@import 'hovers/button-hover-v1-4';

// Hover v2-1
@import 'hovers/button-hover-v2-1';

// Hover v2-2
@import 'hovers/button-hover-v2-2';

//
// Default Buttons
@import 'default/button-default';

//
// Outline Buttons
@import 'outline/button-outline';

//
// Gradient Buttons
@import 'gradient/button-gradients';

//
// Gradient Outline Buttons
@import 'gradient-ouline/button-ouline-gradients';

/* Material Styles
------------------------------------*/
.u-btn-raised,
.u-btn-raised:active:focus,
.u-btn-raised:focus {
  box-shadow: 0 2px 2px 0 rgba($g-color-black, 0.14),
    0 3px 1px -2px rgba($g-color-black, 0.2),
    0 1px 5px 0 rgba($g-color-black, 0.12);
}

//
// Material: Floating
@import 'floating/button-floating';

//
// Material: Flat
@import 'flat/button-flat';
