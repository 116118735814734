/*------------------------------------
  Quantity
------------------------------------*/

.u-quantity {
  &-v1 {
    input:not([type='checkbox'], [type='radio']) {
      background-color: $g-color-white !important;
    }
  }
}

.js-plus,
.js-minus {
  cursor: pointer;
}
