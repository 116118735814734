/*------------------------------------
  Material: Waves
------------------------------------*/
.u-waves-effect {
  position: relative;
  overflow: hidden;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  transition: 0.3s ease-out;

  .u-waves-ripple {
    position: absolute;
    border-radius: 50%;
    @include px-to-rem(width, 20px);
    @include px-to-rem(height, 20px);
    @include px-to-rem(margin-top, -10px);
    @include px-to-rem(margin-left, -10px);
    opacity: 0;
    background: rgba($g-color-black, 0.2);
    transition: all 0.7s ease-out;
    transition-property: transform, opacity;
    transform: scale(0);
    pointer-events: none;
  }

  // Waves Colors
  &.u-waves-light .u-waves-ripple {
    background-color: rgba($g-color-white, 0.45);
  }
  &.u-waves-dark .u-waves-ripple {
    background-color: rgba($g-color-black, 0.15);
  }

  // Style input button bug.
  input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    border: 0;
    font-style: normal;
    font-size: inherit;
    text-transform: inherit;
    background: none;
  }

  img {
    position: relative;
    z-index: -1;
  }
}

.u-waves-notransition {
  transition: none #{'!important'};
}

.u-waves-circle {
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.u-waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;

  .u-waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.u-waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.u-waves-block {
  display: block;
}

/* Firefox Bug: link not triggered */
.u-waves-effect .u-waves-ripple {
  z-index: -1;
}
