//
// Divider Dobule Solid Style
//

/* Double Solid Divider */
.u-divider-db-solid {
  height: 5px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
