/*------------------------------------
  Tags
------------------------------------*/
/* Pagination v1 */
.u-tags-v1 {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
  }
  &:focus {
    color: inherit;
  }
}
