/*------------------------------------
  Navigation Behavior (Push)
------------------------------------*/

body.u-main-nav--push {
  &-left,
  &-right {
    width: 100%;

    .u-main-nav__push {
      overflow: hidden;
    }
  }

  &-right {
    .u-main-nav {
      &--push {
        @include px-to-rem(right, -300px);
      }
      &__overlay {
        right: 100%;
      }
    }
    &.u-main-nav--overlay-opened {
      @include px-to-rem(margin-right, 300px);

      .u-main-nav {
        &--push {
          right: 0;
        }
        &__overlay {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &-left {
    .u-main-nav {
      &--push {
        @include px-to-rem(left, -300px);
      }
      &__overlay {
        left: 100%;
      }
    }
    &.u-main-nav--overlay-opened {
      @include px-to-rem(margin-left, 300px);

      .u-main-nav {
        &--push {
          left: 0;
        }
        &__overlay {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.navbar-collapse.u-main-nav--push {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 5;

  margin: 0 !important;
  @include px-to-rem(width, 300px);
  max-width: 85%;
  align-items: flex-start !important;

  .navbar-nav {
    width: 100%;
    flex-direction: column;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
  }

  .u-main-nav__list-wrapper {
    margin-left: 0;
    margin-right: 0;
    @include px-to-rem(padding, 30px 10px);
    width: 100%;
    height: 100%;

    .navbar-nav {
      @include px-to-rem(padding, 10px);
    }
  }
}
