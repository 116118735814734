/*------------------------------------
  Info Block v1-5
------------------------------------*/
.u-info-v1-5__item {
  position: relative;
  padding-bottom: 19px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    display: block;
    width: 28px;
    border-bottom: 1px solid $g-color-primary;
  }
}
