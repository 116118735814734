/*------------------------------------
  Border Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Primary Colors */
.g-brd-primary {
  border-color: $g-color-primary !important;

  &--before {
    &::before {
      border-color: $g-color-primary !important;
    }

    &--active.active {
      &::before {
        border-color: $g-color-primary !important;
      }
    }
  }

  &--hover:hover,
  &--active.active {
    border-color: $g-color-primary !important;
  }

  // Active
  &--active {
    &.active,
    .active & {
      border-color: $g-color-primary !important;
    }
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-primary !important;
    }
  }

  // Opacity
  &-opacity {
    &-0_3 {
      border-color: rgba($g-color-primary, 0.3) !important;

      &--hover:hover,
      &--active.active {
        border-color: rgba($g-color-primary, 0.3) !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: rgba($g-color-primary, 0.3) !important;
        }
      }
    }
  }

  // Primary dark
  &-dark {
    &-dark-v1 {
      border-color: $g-color-primary-dark-v1 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-primary-dark-v1 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-primary-dark-v1 !important;
        }
      }
    }

    &-dark-v2 {
      border-color: $g-color-primary-dark-v2 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-primary-dark-v2 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-primary-dark-v2 !important;
        }
      }
    }

    &-dark-v3 {
      border-color: $g-color-primary-dark-v3 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-primary-dark-v3 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-primary-dark-v3 !important;
        }
      }
    }
  }
}

.g-brd-bottom-primary {
  &--hover:hover {
    border-bottom-color: $g-color-primary !important;
  }
}

/* Main Colors */
.g-brd-main {
  border-color: $g-color-main !important;

  &--hover:hover {
    border-color: $g-color-main !important;
  }
}

/* Black Colors */
.g-brd-black {
  border-color: $g-color-black !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-black !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-black !important;
    }
  }

  &-opacity {
    &-0_4 {
      border-color: rgba($g-color-black, 0.4) !important;
    }
  }
}

/* White Colors */
.g-brd-white {
  border-color: $g-color-white !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-white !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-white !important;
    }
  }

  // White opacity
  &-opacity {
    &-0_1 {
      border-color: rgba($g-color-white, 0.1) !important;

      &--hover:hover,
      &--active.active {
        border-color: rgba($g-color-white, 0.1) !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: rgba($g-color-white, 0.1) !important;
        }
      }
    }

    &-0_2 {
      border-color: rgba($g-color-white, 0.2) !important;

      &--hover:hover,
      &--active.active {
        border-color: rgba($g-color-white, 0.2) !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: rgba($g-color-white, 0.2) !important;
        }
      }
    }

    &-0_3 {
      border-color: rgba($g-color-white, 0.3) !important;

      &--hover:hover,
      &--active.active {
        border-color: rgba($g-color-white, 0.3) !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: rgba($g-color-white, 0.3) !important;
        }
      }
    }

    &-0_4 {
      border-color: rgba($g-color-white, 0.4) !important;

      &--hover:hover,
      &--active.active {
        border-color: rgba($g-color-white, 0.4) !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: rgba($g-color-white, 0.4) !important;
        }
      }
    }

    &-0_5 {
      border-color: rgba($g-color-white, 0.5) !important;

      &--hover:hover,
      &--active.active {
        border-color: rgba($g-color-white, 0.5) !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: rgba($g-color-white, 0.5) !important;
        }
      }
    }

    &-0_6 {
      border-color: rgba($g-color-white, 0.6) !important;

      &--hover:hover,
      &--active.active {
        border-color: rgba($g-color-white, 0.6) !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: rgba($g-color-white, 0.6) !important;
        }
      }
    }

    &-0_8 {
      border-color: rgba($g-color-white, 0.8) !important;

      &--hover:hover,
      &--active.active {
        border-color: rgba($g-color-white, 0.8) !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: rgba($g-color-white, 0.8) !important;
        }
      }
    }
  }
}

/* Gray Colors */
.g-brd-gray {
  // Dark Gray
  &-dark {
    &-v1 {
      border-color: $g-color-gray-dark-v1 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-gray-dark-v1 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-dark-v1 !important;
        }
      }
    }

    &-v2 {
      border-color: $g-color-gray-dark-v2 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-gray-dark-v2 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-dark-v2 !important;
        }
      }
    }

    &-v3 {
      border-color: $g-color-gray-dark-v3 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-gray-dark-v3 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-dark-v3 !important;
        }
      }
    }

    &-v4 {
      border-color: $g-color-gray-dark-v4 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-gray-dark-v4 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-dark-v4 !important;
        }
      }
    }

    &-v5 {
      border-color: $g-color-gray-dark-v5 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-gray-dark-v5 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-dark-v5 !important;
        }
      }
    }
  }

  // Light Gray
  &-light {
    &-v1 {
      border-color: $g-color-gray-light-v1 !important;

      &--hover:hover,
      &--focus:focus,
      &--active.active {
        border-color: $g-color-gray-light-v1 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-light-v1 !important;
        }
      }
    }

    &-v2 {
      border-color: $g-color-gray-light-v2 !important;

      &--before {
        &::before {
          border-color: $g-color-gray-light-v2 !important;
        }
      }

      &--after {
        &::after {
          border-color: $g-color-gray-light-v2 !important;
        }
      }

      &--hover:hover,
      &--active.active {
        border-color: $g-color-gray-light-v2 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-light-v2 !important;
        }
      }
    }

    &-v3 {
      border-color: $g-color-gray-light-v3 !important;

      &--hover:hover,
      &--active.active,
      &--focus:focus {
        border-color: $g-color-gray-light-v3 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-light-v3 !important;
        }
      }
    }

    &-v4 {
      border-color: $g-color-gray-light-v4 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-gray-light-v4 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-light-v4 !important;
        }
      }
    }

    &-v5 {
      border-color: $g-color-gray-light-v5 !important;

      &--hover:hover,
      &--active.active {
        border-color: $g-color-gray-light-v5 !important;
      }

      &--hover-parent {
        *:hover > & {
          border-color: $g-color-gray-light-v5 !important;
        }
      }
    }
  }
}

/* Transparent */
.g-brd-transparent {
  border-color: transparent !important;

  &--before {
    &::before {
      border-color: transparent !important;
    }
  }

  &--after {
    &::after {
      border-color: transparent !important;
    }
  }

  &--hover:hover,
  &--active.active {
    border-color: transparent !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: transparent !important;
    }
  }
}

/* Complementary Colors
------------------------------------*/
/* Color Green */
.g-brd-green {
  border-color: $g-color-green !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-green !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-green !important;
    }
  }
}

/* Color Blue */
.g-brd-blue {
  border-color: $g-color-blue !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-blue !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-blue !important;
    }
  }

  // Opacity
  &-opacity {
    &-0_1 {
      border-color: rgba($g-color-blue, 0.1) !important;
    }
  }
}

/* Color Light Blue */
.g-brd-lightblue {
  border-color: $g-color-lightblue !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-lightblue !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-lightblue !important;
    }
  }

  &-v1 {
    border-color: $g-color-lightblue-v1 !important;

    &--hover:hover,
    &--active.active {
      border-color: $g-color-lightblue-v1 !important;
    }

    &--hover-parent {
      *:hover > & {
        border-color: $g-color-lightblue-v1 !important;
      }
    }
  }
}

/* Color Dark Blue */
.g-brd-darkblue {
  border-color: $g-color-darkblue !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-darkblue !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-darkblue !important;
    }
  }
}

/* Color Indigo */
.g-brd-indigo {
  border-color: $g-color-indigo !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-indigo !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-indigo !important;
    }
  }
}

/* Color Red */
.g-brd-red {
  border-color: $g-color-red !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-red !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-red !important;
    }
  }
}

/* Color Light Red */
.g-brd-lightred {
  border-color: $g-color-lightred !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-lightred !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-lightred !important;
    }
  }
}

/* Color Dark Red */
.g-brd-darkred {
  border-color: $g-color-darkred !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-darkred !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-darkred !important;
    }
  }
}

/* Color Purple */
.g-brd-purple {
  border-color: $g-color-purple !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-purple !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-purple !important;
    }
  }

  // Opacity
  &-opacity {
    &-0_1 {
      border-color: rgba($g-color-purple, 0.1) !important;
    }
  }
}

/* Color Dark Purple */
.g-brd-darkpurple {
  border-color: $g-color-darkpurple !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-darkpurple !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-darkpurple !important;
    }
  }
}

/* Color Pink */
.g-brd-pink {
  border-color: $g-color-pink !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-pink !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-pink !important;
    }
  }
}

/* Color Orange */
.g-brd-orange {
  border-color: $g-color-orange !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-orange !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-orange !important;
    }
  }
}

/* Color Deep Orange */
.g-brd-deeporange {
  border-color: $g-color-deeporange !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-deeporange !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-deeporange !important;
    }
  }
}

/* Color Yellow */
.g-brd-yellow {
  border-color: $g-color-yellow !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-yellow !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-yellow !important;
    }
  }
}

/* Color Aqua */
.g-brd-aqua {
  border-color: $g-color-aqua !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-aqua !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-aqua !important;
    }
  }
}

/* Color Cyan */
.g-brd-cyan {
  border-color: $g-color-cyan !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-cyan !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-cyan !important;
    }
  }
}

/* Color Teal */
.g-brd-teal {
  border-color: $g-color-teal !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-teal !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-teal !important;
    }
  }
}

/* Color Brown */
.g-brd-brown {
  border-color: $g-color-brown !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-brown !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-brown !important;
    }
  }
}

/* Color Blue Gray */
.g-brd-bluegray {
  border-color: $g-color-bluegray !important;

  &--hover:hover,
  &--active.active {
    border-color: $g-color-bluegray !important;
  }

  &--hover-parent {
    *:hover > & {
      border-color: $g-color-bluegray !important;
    }
  }
}

//Top, Bottom, Left, Right
/* Primary Colors */
.g-brd-primary {
  &-top {
    border-top-color: $g-color-primary !important;

    &--before {
      &::before {
        border-top-color: $g-color-primary !important;
      }
    }
  }

  &-bottom {
    border-bottom-color: $g-color-primary !important;

    &--before {
      &:before {
        border-bottom-color: $g-color-primary !important;
      }
    }
  }

  &-left {
    border-left-color: $g-color-primary !important;

    &--before {
      &:before {
        border-left-color: $g-color-primary !important;
      }
    }
  }

  &-right {
    border-right-color: $g-color-primary !important;

    &--before {
      &:before {
        border-right-color: $g-color-primary !important;
      }
    }
  }

  // Opacity
  &-opacity {
    &-0_3 {
      &-top {
        border-top-color: rgba($g-color-primary, 0.3) !important;
      }

      &-bottom {
        border-bottom-color: rgba($g-color-primary, 0.3) !important;
      }

      &-left {
        border-left-color: rgba($g-color-primary, 0.3) !important;
      }

      &-right {
        border-right-color: rgba($g-color-primary, 0.3) !important;
      }
    }
  }

  // Primary dark
  &-dark {
    &-dark-v1 {
      &-top {
        border-top-color: $g-color-primary-dark-v1 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-primary-dark-v1 !important;
      }

      &-left {
        border-left-color: $g-color-primary-dark-v1 !important;
      }

      &-right {
        border-right-color: $g-color-primary-dark-v1 !important;
      }
    }

    &-dark-v2 {
      &-top {
        border-top-color: $g-color-primary-dark-v2 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-primary-dark-v2 !important;
      }

      &-left {
        border-left-color: $g-color-primary-dark-v2 !important;
      }

      &-right {
        border-right-color: $g-color-primary-dark-v2 !important;
      }
    }

    &-dark-v3 {
      &-top {
        border-top-color: $g-color-primary-dark-v3 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-primary-dark-v3 !important;
      }

      &-left {
        border-left-color: $g-color-primary-dark-v3 !important;
      }

      &-right {
        border-right-color: $g-color-primary-dark-v3 !important;
      }
    }
  }
}

/* Black Colors */
.g-brd-black {
  &-top {
    border-top-color: $g-color-black !important;
  }

  &-bottom {
    border-bottom-color: $g-color-black !important;
  }

  &-left {
    border-left-color: $g-color-black !important;
  }

  &-right {
    border-right-color: $g-color-black !important;
  }
}

/* White */
.g-brd-white {
  &-top {
    border-top-color: $g-color-white !important;
  }

  &-bottom {
    border-bottom-color: $g-color-white !important;
  }

  &-left {
    border-left-color: $g-color-white !important;
  }

  &-right {
    border-right-color: $g-color-white !important;
  }

  // White opacity
  &-opacity {
    &-0_1 {
      &-top {
        border-top-color: rgba($g-color-white, 0.1) !important;
      }

      &-bottom {
        border-bottom-color: rgba($g-color-white, 0.1) !important;
      }

      &-left {
        border-left-color: rgba($g-color-white, 0.1) !important;
      }

      &-right {
        border-right-color: rgba($g-color-white, 0.1) !important;
      }
    }

    &-0_2 {
      &-top {
        border-top-color: rgba($g-color-white, 0.2) !important;
      }

      &-bottom {
        border-bottom-color: rgba($g-color-white, 0.2) !important;
      }

      &-left {
        border-left-color: rgba($g-color-white, 0.2) !important;
      }

      &-right {
        border-right-color: rgba($g-color-white, 0.2) !important;
      }
    }

    &-0_3 {
      &-top {
        border-top-color: rgba($g-color-white, 0.3) !important;
      }

      &-bottom {
        border-bottom-color: rgba($g-color-white, 0.3) !important;
      }

      &-left {
        border-left-color: rgba($g-color-white, 0.3) !important;
      }

      &-right {
        border-right-color: rgba($g-color-white, 0.3) !important;
      }
    }

    &-0_4 {
      &-top {
        border-top-color: rgba($g-color-white, 0.4) !important;
      }

      &-bottom {
        border-bottom-color: rgba($g-color-white, 0.4) !important;
      }

      &-left {
        border-left-color: rgba($g-color-white, 0.4) !important;
      }

      &-right {
        border-right-color: rgba($g-color-white, 0.4) !important;
      }
    }

    &-0_5 {
      &-top {
        border-top-color: rgba($g-color-white, 0.5) !important;
      }

      &-bottom {
        border-bottom-color: rgba($g-color-white, 0.5) !important;
      }

      &-left {
        border-left-color: rgba($g-color-white, 0.5) !important;
      }

      &-right {
        border-right-color: rgba($g-color-white, 0.5) !important;
      }
    }

    &-0_6 {
      &-top {
        border-top-color: rgba($g-color-white, 0.6) !important;
      }

      &-bottom {
        border-bottom-color: rgba($g-color-white, 0.6) !important;
      }

      &-left {
        border-left-color: rgba($g-color-white, 0.6) !important;
      }

      &-right {
        border-right-color: rgba($g-color-white, 0.6) !important;
      }
    }
  }
}

/* Gray Colors */
.g-brd-gray {
  // Dark Gray
  &-dark {
    &-v1 {
      &-top {
        border-top-color: $g-color-gray-dark-v1 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-dark-v1 !important;
      }

      &-left {
        border-left-color: $g-color-gray-dark-v1 !important;
      }

      &-right {
        border-right-color: $g-color-gray-dark-v1 !important;
      }
    }

    &-v2 {
      &-top {
        border-top-color: $g-color-gray-dark-v2 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-dark-v2 !important;
      }

      &-left {
        border-left-color: $g-color-gray-dark-v2 !important;
      }

      &-right {
        border-right-color: $g-color-gray-dark-v2 !important;
      }
    }

    &-v3 {
      &-top {
        border-top-color: $g-color-gray-dark-v3 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-dark-v3 !important;
      }

      &-left {
        border-left-color: $g-color-gray-dark-v3 !important;
      }

      &-right {
        border-right-color: $g-color-gray-dark-v3 !important;
      }
    }

    &-v4 {
      &-top {
        border-top-color: $g-color-gray-dark-v4 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-dark-v4 !important;
      }

      &-left {
        border-left-color: $g-color-gray-dark-v4 !important;
      }

      &-right {
        border-right-color: $g-color-gray-dark-v4 !important;
      }
    }

    &-v5 {
      &-top {
        border-top-color: $g-color-gray-dark-v5 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-dark-v5 !important;
      }

      &-left {
        border-left-color: $g-color-gray-dark-v5 !important;
      }

      &-right {
        border-right-color: $g-color-gray-dark-v5 !important;
      }
    }
  }

  // Light Gray
  &-light {
    &-v1 {
      &-top {
        border-top-color: $g-color-gray-light-v1 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-light-v1 !important;
      }

      &-left {
        border-left-color: $g-color-gray-light-v1 !important;
      }

      &-right {
        border-right-color: $g-color-gray-light-v1 !important;
      }
    }

    &-v2 {
      &-top {
        border-top-color: $g-color-gray-light-v2 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-light-v2 !important;
      }

      &-left {
        border-left-color: $g-color-gray-light-v2 !important;
      }

      &-right {
        border-right-color: $g-color-gray-light-v2 !important;
      }
    }

    &-v3 {
      &-top {
        border-top-color: $g-color-gray-light-v3 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-light-v3 !important;
      }

      &-left {
        border-left-color: $g-color-gray-light-v3 !important;
      }

      &-right {
        border-right-color: $g-color-gray-light-v3 !important;
      }
    }

    &-v4 {
      &-top {
        border-top-color: $g-color-gray-light-v4 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-light-v4 !important;
      }

      &-left {
        border-left-color: $g-color-gray-light-v4 !important;
      }

      &-right {
        border-right-color: $g-color-gray-light-v4 !important;
      }
    }

    &-v5 {
      &-top {
        border-top-color: $g-color-gray-light-v5 !important;
      }

      &-bottom {
        border-bottom-color: $g-color-gray-light-v5 !important;
      }

      &-left {
        border-left-color: $g-color-gray-light-v5 !important;
      }

      &-right {
        border-right-color: $g-color-gray-light-v5 !important;
      }
    }
  }
}

/* Transparent */
.g-brd-transparent {
  &-top {
    border-top-color: transparent !important;
  }

  &-bottom {
    border-bottom-color: transparent !important;
  }

  &-left {
    border-left-color: transparent !important;
  }

  &-right {
    border-right-color: transparent !important;
  }
}

/* Complementary Colors
------------------------------------*/
/* Color Green */
.g-brd-green {
  &-top {
    border-top-color: $g-color-green !important;
  }

  &-bottom {
    border-bottom-color: $g-color-green !important;
  }

  &-left {
    border-left-color: $g-color-green !important;
  }

  &-right {
    border-right-color: $g-color-green !important;
  }
}

/* Color Blue */
.g-brd-blue {
  &-top {
    border-top-color: $g-color-blue !important;
  }

  &-bottom {
    border-bottom-color: $g-color-blue !important;
  }

  &-left {
    border-left-color: $g-color-blue !important;
  }

  &-right {
    border-right-color: $g-color-blue !important;
  }
}

/* Color Light Blue */
.g-brd-lightblue {
  &-top {
    border-top-color: $g-color-lightblue !important;
  }

  &-bottom {
    border-bottom-color: $g-color-lightblue !important;
  }

  &-left {
    border-left-color: $g-color-lightblue !important;
  }

  &-right {
    border-right-color: $g-color-lightblue !important;
  }

  &-v1 {
    &-top {
      border-top-color: $g-color-lightblue-v1 !important;
    }

    &-bottom {
      border-bottom-color: $g-color-lightblue-v1 !important;
    }

    &-left {
      border-left-color: $g-color-lightblue-v1 !important;
    }

    &-right {
      border-right-color: $g-color-lightblue-v1 !important;
    }
  }
}

/* Color Dark Blue */
.g-brd-darkblue {
  &-top {
    border-top-color: $g-color-darkblue !important;
  }

  &-bottom {
    border-bottom-color: $g-color-darkblue !important;
  }

  &-left {
    border-left-color: $g-color-darkblue !important;
  }

  &-right {
    border-right-color: $g-color-darkblue !important;
  }
}

/* Color Indigo */
.g-brd-indigo {
  &-top {
    border-top-color: $g-color-indigo !important;
  }

  &-bottom {
    border-bottom-color: $g-color-indigo !important;
  }

  &-left {
    border-left-color: $g-color-indigo !important;
  }

  &-right {
    border-right-color: $g-color-indigo !important;
  }
}

/* Color Red */
.g-brd-red {
  &-top {
    border-top-color: $g-color-red !important;
  }

  &-bottom {
    border-bottom-color: $g-color-red !important;
  }

  &-left {
    border-left-color: $g-color-red !important;
  }

  &-right {
    border-right-color: $g-color-red !important;
  }
}

/* Color Light Red */
.g-brd-lightred {
  &-top {
    border-top-color: $g-color-lightred !important;
  }

  &-bottom {
    border-bottom-color: $g-color-lightred !important;
  }

  &-left {
    border-left-color: $g-color-lightred !important;
  }

  &-right {
    border-right-color: $g-color-lightred !important;
  }
}

/* Color Dark Red */
.g-brd-darkred {
  &-top {
    border-top-color: $g-color-darkred !important;
  }

  &-bottom {
    border-bottom-color: $g-color-darkred !important;
  }

  &-left {
    border-left-color: $g-color-darkred !important;
  }

  &-right {
    border-right-color: $g-color-darkred !important;
  }
}

/* Color Purple */
.g-brd-purple {
  &-top {
    border-top-color: $g-color-purple !important;
  }

  &-bottom {
    border-bottom-color: $g-color-purple !important;
  }

  &-left {
    border-left-color: $g-color-purple !important;
  }

  &-right {
    border-right-color: $g-color-purple !important;
  }
}

/* Color Dark Purple */
.g-brd-darkpurple {
  &-top {
    border-top-color: $g-color-darkpurple !important;
  }

  &-bottom {
    border-bottom-color: $g-color-darkpurple !important;
  }

  &-left {
    border-left-color: $g-color-darkpurple !important;
  }

  &-right {
    border-right-color: $g-color-darkpurple !important;
  }
}

/* Color Pink */
.g-brd-pink {
  &-top {
    border-top-color: $g-color-pink !important;
  }

  &-bottom {
    border-bottom-color: $g-color-pink !important;
  }

  &-left {
    border-left-color: $g-color-pink !important;
  }

  &-right {
    border-right-color: $g-color-pink !important;
  }
}

/* Color Orange */
.g-brd-orange {
  &-top {
    border-top-color: $g-color-orange !important;
  }

  &-bottom {
    border-bottom-color: $g-color-orange !important;
  }

  &-left {
    border-left-color: $g-color-orange !important;
  }

  &-right {
    border-right-color: $g-color-orange !important;
  }
}

/* Color Deep Orange */
.g-brd-deeporange {
  &-top {
    border-top-color: $g-color-deeporange !important;
  }

  &-bottom {
    border-bottom-color: $g-color-deeporange !important;
  }

  &-left {
    border-left-color: $g-color-deeporange !important;
  }

  &-right {
    border-right-color: $g-color-deeporange !important;
  }
}

/* Color Yellow */
.g-brd-yellow {
  &-top {
    border-top-color: $g-color-yellow !important;
  }

  &-bottom {
    border-bottom-color: $g-color-yellow !important;
  }

  &-left {
    border-left-color: $g-color-yellow !important;
  }

  &-right {
    border-right-color: $g-color-yellow !important;
  }
}

/* Color Aqua */
.g-brd-aqua {
  &-top {
    border-top-color: $g-color-aqua !important;
  }

  &-bottom {
    border-bottom-color: $g-color-aqua !important;
  }

  &-left {
    border-left-color: $g-color-aqua !important;
  }

  &-right {
    border-right-color: $g-color-aqua !important;
  }
}

/* Color Cyan */
.g-brd-cyan {
  &-top {
    border-top-color: $g-color-cyan !important;
  }

  &-bottom {
    border-bottom-color: $g-color-cyan !important;
  }

  &-left {
    border-left-color: $g-color-cyan !important;
  }

  &-right {
    border-right-color: $g-color-cyan !important;
  }
}

/* Color Teal */
.g-brd-teal {
  &-top {
    border-top-color: $g-color-teal !important;
  }

  &-bottom {
    border-bottom-color: $g-color-teal !important;
  }

  &-left {
    border-left-color: $g-color-teal !important;
  }

  &-right {
    border-right-color: $g-color-teal !important;
  }
}

/* Color Brown */
.g-brd-brown {
  &-top {
    border-top-color: $g-color-brown !important;
  }

  &-bottom {
    border-bottom-color: $g-color-brown !important;
  }

  &-left {
    border-left-color: $g-color-brown !important;
  }

  &-right {
    border-right-color: $g-color-brown !important;
  }
}

/* Color Blue Gray */
.g-brd-bluegray {
  &-top {
    border-top-color: $g-color-bluegray !important;
  }

  &-bottom {
    border-bottom-color: $g-color-bluegray !important;
  }

  &-left {
    border-left-color: $g-color-bluegray !important;
  }

  &-right {
    border-right-color: $g-color-bluegray !important;
  }
}

/* O */
.g-brd {
  &-primary {
    &-top {
      &--hover:hover {
        border-top-color: $g-color-primary !important;

        &::after {
          border-top-color: $g-color-primary;
        }
      }

      &--active.active {
        border-top-color: $g-color-primary !important;

        &::after {
          border-top-color: $g-color-primary;
        }
      }
    }

    &-bottom {
      &--hover:hover {
        border-bottom-color: $g-color-primary !important;

        &::after {
          border-bottom-color: $g-color-primary;
        }
      }

      &--active.active {
        border-bottom-color: $g-color-primary !important;

        &::after {
          border-bottom-color: $g-color-primary;
        }
      }
    }
  }
}

.g-brd {
  &-pinterest {
    border-color: $g-color-pinterest !important;

    &::after {
      border-color: $g-color-pinterest !important;
    }

    &-top {
      &--hover:hover {
        border-top-color: $g-color-pinterest !important;

        &::after {
          border-top-color: $g-color-pinterest;
        }
      }

      &--active.active {
        border-top-color: $g-color-pinterest !important;

        &::after {
          border-top-color: $g-color-pinterest;
        }
      }
    }

    &-bottom {
      &--hover:hover {
        border-bottom-color: $g-color-pinterest !important;

        &::after {
          border-bottom-color: $g-color-pinterest;
        }
      }

      &--active.active {
        border-bottom-color: $g-color-pinterest !important;

        &::after {
          border-bottom-color: $g-color-pinterest;
        }
      }
    }
  }
}
