/*------------------------------------
  Checkboxes v4
------------------------------------*/

.u-check {
  &-icon {
    &-checkbox,
    &-radio {
      // v4
      &-v4 {
        width: 18px;
        height: 18px;
        font-size: 12px;
        border: solid 1px $g-color-gray-light-v2;

        i {
          &::before {
            content: attr(data-uncheck-icon);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      // End v4
    }

    &-checkbox {
      // v4
      // &-v4 {
      //   border-radius: 1px;

      //   i {
      //     border-radius: 1px;
      //   }
      // }
      // End v4
    }

    &-radio {
      // v4
      &-v4 {
        border-radius: 50%;

        i {
          border-radius: 50%;
        }
      }
      // End v4
    }
  }

  // Control
  input[type='checkbox'],
  input[type='radio'] {
    &:checked {
      & +,
      & + * {
        // v4
        .u-check-icon-checkbox-v4,
        .u-check-icon-radio-v4 {
          color: $g-color-primary;
          border-color: $g-color-primary;

          i {
            &::before {
              content: attr(data-check-icon);
            }
          }
        }
        // End v4
      }
    }
  }
}
