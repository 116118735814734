/*------------------------------------
  Box-shadows-v1
------------------------------------*/

.u-shadow-v1 {
  &-1 {
    box-shadow: 0 0 5px $g-color-gray-dark-v5;
  }
  &-2 {
    box-shadow: 0 0 10px $g-color-gray-dark-v5;

    &--hover:hover {
      box-shadow: 0 0 10px $g-color-gray-dark-v5;
    }

    &--hover-parent {
      .g-parent:hover & {
        box-shadow: 0 0 10px $g-color-gray-dark-v5;
      }
    }
  }
  &-3 {
    box-shadow: 0 0 5px $g-color-gray-light-v3;
  }
  &-4 {
    box-shadow: 0 0 5px rgba($g-color-black, 0.1);
  }
  &-5 {
    box-shadow: 0 0 20px rgba($g-color-black, 0.2);

    &--hover:hover {
      box-shadow: 0 0 20px rgba($g-color-black, 0.2);
    }

    &--hover-parent {
      *:hover > & {
        box-shadow: 0 0 20px rgba($g-color-black, 0.2);
      }
    }
  }
  &-6 {
    box-shadow: 0 0 10px rgba($g-color-black, 0.2);

    &--hover:hover {
      box-shadow: 0 0 10px rgba($g-color-black, 0.2);
    }

    &--hover-parent {
      *:hover > & {
        box-shadow: 0 0 10px rgba($g-color-black, 0.2);
      }
    }
  }

  &-7 {
    box-shadow: 0 0 8px $g-color-gray-light-v4;

    &--hover:hover {
      box-shadow: 0 0 8px $g-color-gray-light-v4;
    }

    &--hover-parent {
      *:hover > & {
        box-shadow: 0 0 8px $g-color-gray-light-v4;
      }
    }
  }
}
