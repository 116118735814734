/*------------------------------------
  Info Block v5-4
------------------------------------*/
/* Actions */
.info-v5-4__action {
  .fa {
    opacity: 0.75;
  }

  &:hover {
    .fa {
      opacity: 1;
    }
  }
}
